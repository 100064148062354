import React, { Component } from "react";
import {Link} from "react-router-dom";
import Routes from "../../../common/util/Routes";

/**
 * Handles creation of the User Settings pages navigation.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class SettingsNavigation extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="row page_nav">
                <div className="col">
                    <ul className="list-inline d-flex justify-content-start">
                        <li className={"list-inline-item mr-4 " + this.tryAddActiveClass(Routes.app.user.settings.profile)}>
                            <Link to={Routes.app.user.settings.profile}>My Profile</Link>
                        </li>
                        <li className={"list-inline-item mr-4 " + this.tryAddActiveClass(Routes.app.user.settings.team)}>
                            <Link to={Routes.app.user.settings.team}>My Team</Link>
                        </li>
                        <li className={"list-inline-item mr-4 " + this.tryAddActiveClass(Routes.app.user.settings.company)}>
                            <Link to={Routes.app.user.settings.company}>My Company</Link>
                        </li>
                        <li className={"list-inline-item mr-4 " + this.tryAddActiveClass(Routes.app.user.settings.password)}>
                            <Link to={Routes.app.user.settings.password}>Password</Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    tryAddActiveClass(route) {
        return route.includes(this.props.location.pathname) ? "active" : "";
    }
}

export default SettingsNavigation;