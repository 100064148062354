/**
 * Contains useful constants related to URLs/routes used by the front-end.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
const Routes = {

    app: {
        admin: {
            base: '/app/admin',
            dashboard: '/app/admin/dashboard.html',
            notifications: '/app/admin/notifications.html',
            users: '/app/admin/users.html',
        },
        dashboard: '/app/dashboard.html',
        gig: {
            base: '/app/gig.html',
            archive: '/app/gig/archive.html'
        },
        logout: '/app/logout.html',
        organization: {
            base: '/app/organization',
            teams: {
                base: '/app/organization/teams.html',
                member: '/app/organization/teams/member.html'
            }
        },
        reporting: {
            base: '/app/reporting',
            automationSuggestions: '/app/reporting/automation-suggestions.html'
        },
        user: {
            base: '/app/user',
            notifications: '/app/user/notifications.html',
            settings: {
                base: '/app/user/settings',
                profile: '/app/user/settings/profile.html',
                team: '/app/user/settings/team.html',
                company: '/app/user/settings/company.html',
                password: '/app/user/settings/password.html'
            },
            signup: {
                welcome: {
                    base: '/app/user/welcome',
                    how: '/app/user/welcome/how.html',
                    who: '/app/user/welcome/who.html',
                    when: '/app/user/welcome/when.html',
                    drag: '/app/user/welcome/drag.html'
                }
            },
            upgrade: '/app/user/upgrade.html'
        }
    },

    ajax: {
        admin: {
            getAllData: '/ajax/admin/data',
            getAllUsers: '/ajax/admin/users',
            notifications: '/ajax/admin/notifications'
        },
        gig: {
            add: '/ajax/app/gig/add',
            getAllByUser: '/ajax/app/gig/getAllByUser',
            get: '/ajax/app/gig/get',
            update: '/ajax/app/gig/update',
            attachment: {
                download: '/ajax/app/gig/attachment?id=',
                upload: '/ajax/app/gig/attachment'
            },
            comment: {
                add: '/ajax/app/gig/comment'
            },
            share: '/ajax/app/gig/share',
            tag: {
                add: '/ajax/app/gig/tag/add',
                remove: '/ajax/app/gig/tag/remove',
                removeAll: '/ajax/app/gig/tag/removeAll'
            }
        },
        user: {
            getCurrentUser: '/ajax/app/user',
            getProfileImage: '/ajax/app/user/profile-image',
            industries: '/ajax/app/user/industries',
            notifications: '/ajax/app/user/notifications',
            preferences: '/ajax/app/user/preferences',
            sendInvitations: '/ajax/app/user/send-invitations',
            update: {
                signup: {
                    welcome: '/ajax/app/user/signup/welcome'
                },
                password: '/ajax/app/user/updatePassword',
                profile: '/ajax/app/user/profileUpdate'
            }
        },
        team: {
            base: '/ajax/app/team'
        }
    }
};

export default Routes;