import ContentEditable from "react-contenteditable";
import React from "react";
import Routes from "../../common/util/Routes";
import SecureComponent from "../../common/util/SecureComponent";
import UserService from "../../common/user/UserService";
import Cookies from "../../common/util/Cookies";
import Activity from "./activity/Activity";
import Tags from "./Tags";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Status from "./Status";
import Attachments from "./Attachments";

/**
 * Displays the main Gig view screen.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class ViewGig extends SecureComponent {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        UserService.getUserGig(this.props.id, data => {
            this.setState({
                gig: data
            });
        });

        UserService.getUserProfileImage(profileImage => {
            this.setState({
                user: Object.assign({}, this.state.user, {...this.state.user, profileImage: profileImage})
            });
        });
    }

    render() {
        const CustomDatePicker = React.forwardRef(({ value, onClick, ...props }, ref) => (
            <span className="bg-primary text-whited-inline-block py-2 px-3 rounded-lg" ref={ref} onClick={onClick}>
                <i className="far fa-calendar-alt mr-2"></i> {props.preValueText}: {moment(value, 'YYYY-MM-DD hh:mm A').format('MM/DD/YYYY')}
            </span>
        ));

        return(
            <div>
                <div className="filled_gig bg-dk-green show">
                    <div className="close_gig">
                        <i className="fas fa-times" onClick={this._close.bind(this)} />
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 gig_info">
                            <form>
                                <div className="form-group">
                                    <h1 className="h2">
                                        <ContentEditable
                                            html={this.state.gig.title}
                                            disabled={false}
                                            onBlur={this._updateGigTitle.bind(this)} />
                                    </h1>

                                    <DatePicker
                                        selected={moment.utc(this.state.gig.startDate).local().toDate()}
                                        onChange={(date) => this._updateGigStartDate(date)}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd hh:mm aa"
                                        timeFormat="hh:mm aa"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        onChangeRaw={e => e.preventDefault()}
                                        autoComplete="off"
                                        customInput={<CustomDatePicker preValueText={"Start"} />}
                                    />
                                    <DatePicker
                                        selected={moment.utc(this.state.gig.dueDate).local().toDate()}
                                        onChange={(date) => this._updateGigDueDate(date)}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd hh:mm aa"
                                        timeFormat="hh:mm aa"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        onChangeRaw={e => e.preventDefault()}
                                        autoComplete="off"
                                        customInput={<CustomDatePicker preValueText={"Due"} />}
                                    />
                                </div>
                                <div className="form-group">
                                    {// TODO: Determine how to pass description inside of <p> element
                                    }
                                    <ContentEditable
                                        html={this.state.gig.description}
                                        disabled={false}
                                        onBlur={this._updateGigDescription.bind(this)} />
                                </div>

                                <Attachments gig={this.state.gig}
                                             updateAttachmentList={this._updateAttachmentList.bind(this)}
                                />

                                <Tags tags={this.state.gig.tags}
                                      gig={this.state.gig}
                                      updateTagList={this._updateTagList.bind(this)}
                                      setTagInactive={this._setTagInactive.bind(this)}
                                      setAllTagsInactive={this._setAllTagsInactive.bind(this)}
                                />

                                <Status gig={this.state.gig}
                                        setStatus={this._setStatus.bind(this)}
                                />
                            </form>
                            <div className="row">
                                <div className="col icon_nav mt-5">
                                    <a href="#" data-toggle="modal" data-placement="bottom" data-target="#share_modal"
                                       title="Assign to someone">
                                        <i className="fas fa-user-circle" />
                                    </a>
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Add some tags">
                                        <i className="fas fa-tags" />
                                    </a>
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Add an attachment">
                                        <i className="fas fa-paperclip" />
                                    </a>
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Priority level">
                                        <i className="fas fa-star" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col gig_activity_column">
                            <h2 className="h2">Activity</h2>
                            <hr />

                            <Activity gig={this.state.gig} user={this.state.user} />

                            <div className="gig_activity">
                                <div className="gig_activity__event">
                                    <div className="gig_activity__user">
                                        <img src={this.state.user.profileImage} alt="user image"
                                             className="rounded-circle img-fluid" />
                                    </div>
                                    <div className="gig_activity__new_comment">
                                        <div className="form-group mt-5">
                                            <textarea
                                                className="form-control"
                                                id="newGigComments"
                                                placeholder=""
                                                value={this.state.currentComment}
                                                onChange={(e) => this.setState({currentComment: e.target.value})}
                                            />
                                        </div>
                                        <div className="text-right">
                                            <div className={"btn btn-dark " + (this.state.addCommentDisabled ? "disabled" : "")}
                                                 onClick={this._addComment.bind(this)}>Add comment</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            addCommentDisabled: false,
            currentComment: '',
            gig: {
                title: '',
                description: '',
                createdBy: {
                    name: ''
                },
                createDate: '',
                activities: [],
                attachments: [],
                tags: [],
                status: 'BACKLOG',
                comments: []
            },
            user: {
                profileImage: ''
            }
        };

        if (props.gig) {
            initialState.gig = props.gig
        }

        return initialState;
    }

    /**
     * Adds a comment to the current gig.
     *
     * @private
     */
    _addComment() {
        if (this.state.addCommentDisabled) {
            return;
        }

        // Disable the add comment button while submitting so that a user cannot accidentally submit the same comment twice
        this.setState({
            addCommentDisabled: true
        });

        if (!this.state.currentComment || this.state.currentComment.length === 0) {
            console.log('A comment must be have content.');

            this.setState({
                addCommentDisabled: false
            });

            return;
        }

        console.log('Adding comment to gig [' + this.state.gig.id + ']: ' + this.state.currentComment);

        fetch(Routes.ajax.gig.comment.add, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gigId: this.state.gig.id,
                comment: this.state.currentComment
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to add new comment: " + error);

            this.setState({
                addCommentDisabled: false
            });
        })
        .then(
            function(response) {
                if (!response) {
                    // TODO: Show error message
                    console.log("Unable to add new comment: " + response);
                }

                let comments = this.state.gig.comments;
                comments.push(response);

                this.setState({
                    gig: Object.assign({}, this.state.gig, {...this.state.gig, comments: comments}),
                    currentComment: '',
                    addCommentDisabled: false
                });
            }.bind(this),
            function(error) {
                // TODO: Show an error message
                console.log("Unable to add new comment: " + error);

                this.setState({
                    addCommentDisabled: false
                });
            }
        );
    }

    /**
     * Closes the view gig popup, displaying the user's dashboard.
     *
     * @private
     */
    _close() {
        this.props.history.push(Routes.app.dashboard);
    }

    /**
     *
     * @param event
     * @private
     */
    _updateGigTitle(event) {
        let content = event.target.innerText;

        // Only speak to backend when something actually changes
        if (content === this.state.gig.title) {
            return;
        }

        // Update component state
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, title: content})
        });

        // Persist changes to backend
        this._updateGig({
            id: this.state.gig.id,
            title: content,
            description: this.state.gig.description,
            dueDate: this.state.gig.dueDate,
            startDate: this.state.gig.startDate,
            status: this.state.gig.status
        });
    }

    /**
     *
     * @param event
     * @private
     */
    _updateGigDescription(event) {
        let content = event.target.innerText;

        // Only speak to backend when something actually changes
        if (content === this.state.gig.description) {
            return;
        }

        // Update component state
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, description: content})
        });

        // Persist changes to backend
        this._updateGig({
            id: this.state.gig.id,
            title: this.state.gig.title,
            description: content,
            dueDate: this.state.gig.dueDate,
            startDate: this.state.gig.startDate,
            status: this.state.gig.status
        });
    }

    /**
     *
     * @param date
     * @private
     */
    _updateGigStartDate(date) {
        let utcDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, startDate: utcDate})
        });

        this._updateGig({
            id: this.state.gig.id,
            title: this.state.gig.title,
            description: this.state.gig.description,
            dueDate: this.state.gig.dueDate,
            startDate: utcDate,
            status: this.state.gig.status
        });
    }

    /**
     *
     * @param date
     * @private
     */
    _updateGigDueDate(date) {
        let utcDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, dueDate: utcDate})
        });

        this._updateGig({
            id: this.state.gig.id,
            title: this.state.gig.title,
            description: this.state.gig.description,
            dueDate: utcDate,
            startDate: this.state.gig.startDate,
            status: this.state.gig.status
        });
    }

    /**
     *
     * @private
     */
    _updateGig(gig) {
        console.log("Updating gig... [" + JSON.stringify(gig) + "]");

        // Save new Gig
        fetch(Routes.ajax.gig.update, {
            method: 'PATCH',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(gig)
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to update gig: " + error);
        })
        .then(
            function(response) {
                if (response !== 'SUCCESS') {
                    // TODO: Show error message
                    console.log("Unable to add new gig: " + response);
                }
            }.bind(this),
            function(error) {
                // TODO: Show an error message
                console.error(error);
            }
        )
        .then(function() {
            UserService.getUserGigs(data => this.props.loadUserGigs(data));
        }.bind(this));
    }

    /**
     *
     * @param attachment
     * @private
     */
    _updateAttachmentList(attachment) {
        let attachments = this.state.gig.attachments;
        attachments.push(attachment);

        // Update component state
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, attachments: attachments})
        });
    }

    /**
     *
     * @param tag
     * @private
     */
    _updateTagList(tag) {
        let tags = this.state.gig.tags;
        tags.push(tag);

        // Update component state
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, tags: tags})
        });
    }

    /**
     *
     * @param tagId
     * @private
     */
    _setTagInactive(tagId) {
        let tags = this.state.gig.tags.map(tag => {
            if (tag.id === tagId) {
                tag.active = false;
            }

            return tag;
        });

        // Update component state
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, tags: tags})
        });
    }

    /**
     *
     * @private
     */
    _setAllTagsInactive() {
        let tags = this.state.gig.tags.map(tag => {
            tag.active = false;

            return tag;
        });

        // Update component state
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, tags: tags})
        });
    }

    /**
     * Updates the status of the gig when a user selects a new status.
     *
     * @param status the new gig status
     * @private
     */
    _setStatus(status) {
        this.setState({
            gig: Object.assign({}, this.state.gig, {...this.state.gig, status: status})
        });

        this._updateGig({
            id: this.state.gig.id,
            title: this.state.gig.title,
            description: this.state.gig.description,
            dueDate: this.state.gig.dueDate,
            startDate: this.state.gig.startDate,
            status: status
        });
    }

}

export default ViewGig;