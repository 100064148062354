import React  from "react";
import SecureComponent from "../../../../common/util/SecureComponent";
import Routes from "../../../../common/util/Routes";
import {Link} from "react-router-dom";

/**
 * Main entrypoint for the user welcome flow pages.
 *
 * @author Adam Childs
 */
class When extends SecureComponent {

    constructor(props) {
        super(props);
    }

    // TODO: Drag animation support for selecting working days
    render() {
        return(
            <div>
                <div className="row page_nav">
                    <div className="col-md-7">
                        <h1>When does your team normally work?</h1>
                        <p>When we know when you prefer to work, we optimize and plan better.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col my-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex calendar">
                                            <div className="calendar_gigs">
                                                <div className="calendar_gig bar bar-sm show_filled_gig"
                                                     style={{width: '250px'}}>

                                                </div>
                                            </div>
                                            <div className="p-2 flex-fill calendar__day">Su</div>
                                            <div className="p-2 flex-fill calendar__day">Mo</div>
                                            <div className="p-2 flex-fill calendar__day">Tu</div>
                                            <div className="p-2 flex-fill calendar__day">We</div>
                                            <div className="p-2 flex-fill calendar__day">Th</div>
                                            <div className="p-2 flex-fill calendar__day">Fr</div>
                                            <div className="p-2 flex-fill calendar__day">Sa</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Link to={Routes.app.dashboard} id="next_step" className="btn btn-primary mb-4">Continue</Link>
                                <p><Link to={Routes.app.dashboard}>Skip this step</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default When;