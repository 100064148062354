import React, { Component } from "react";
import Select from 'react-select';
import Routes from "../../../common/util/Routes";
import Cookies from "../../../common/util/Cookies";

/**
 * Displays a Selector that allows a user to select their work industry.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class UserIndustrySelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: null,
            industries: []
        };
    }

    componentDidMount() {
        this._initializeIndustries();
    }

    handleChange(selectedOption) {
        this.setState({ selectedOption });
    }

    render() {
        const { selectedOption } = this.state;

        return (
            <Select
                id={"industry"}
                name={"industry"}
                value={selectedOption}
                onChange={this.handleChange.bind(this)}
                options={this.state.industries}
                classNamePrefix={"sg-select"}
                placeholder={"My team does:"}
            />
        );
    }

    /**
     *
     * @private
     */
    _initializeIndustries() {
        // Fetch and then setState
        fetch(Routes.ajax.user.industries, {
            method: 'GET',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to retrieve industries: " + error);
        })
        .then(function(industries) {
            if (!industries) {
                console.warn("Found no industries, or backend is temporarily unavailable.");

                return;
            }

            console.debug("Found active industries [" + industries.length + "]");

            this.setState({
                industries: industries
            })
        }.bind(this));
    }
}

export default UserIndustrySelector;