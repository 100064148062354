/**
 * Provides utility functions for operating on miscellaneous data (i.e. ArrayBuffers, etc.).
 *
 * @author Adam Childs
 */
class DataUtil {

    /**
     * Converts the given array buffer to a Base64 encoded String.
     *
     * @returns a Base64 encoded String that represents the given buffer
     */
    static arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}

export default DataUtil;