import React  from "react";
import SecureComponent from "../../common/util/SecureComponent";

// TODO: Lots here!
class NotFound extends SecureComponent {

    render() {
        return(
            <div>
                404 - Not Found
            </div>
        );
    }
}

export default NotFound;