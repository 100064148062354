import Routes from "../../../common/util/Routes";

/**
 * Provides utility functions for operating the currently authenticated SuiteGig user's Team.
 *
 * @author Adam Childs
 */
class TeamService {

    /**
     *
     * @param callback
     */
    static getUserTeam(callback) {
        fetch(Routes.ajax.team.base, {
            method: 'GET'
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to retrieve user team: " + error);
        })
        .then(response => {
            if (!response) {
                console.log('no data');

                return;
            }

            callback(response);
        });
    }

}

export default TeamService;