import React, { Component } from "react";
import UserDropdown from "./UserDropdown";
import {Link} from "react-router-dom";
import Routes from "../../../common/util/Routes";

/**
 * Represents the SuiteGig app header.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Header extends Component {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            notifications: props.notifications
        };
    }

    render() {
        return(
            <div className="row">
                <div className="col">
                    <nav className="app_header">
                        <div className="row">
                            <div className="col">
                                <form className="form-inline">
                                    <div className="form-group search">
                                        <label className="sr-only" htmlFor="searchApp">Search</label>
                                        <input className="form-control" id="searchApp" aria-describedby="searchHelp"
                                               placeholder="Search" />
                                        <i className="fas fa-search" />
                                    </div>
                                </form>
                            </div>
                            <div className="col">
                                <ul className="list-inline d-flex justify-content-end">
                                    <li className="list-inline-item mr-3">
                                        <Link to={Routes.app.user.upgrade} className="sign-up">Upgrade</Link>
                                    </li>
                                    <li className="list-inline-item pt-2 mr-4 notifications">
                                        <a href="#" className="" id="alertDropdown" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-bell" />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="userDropdown">
                                            {this.state.notifications.length > 0 ?
                                                this.state.notifications.map(notification => {
                                                    return (
                                                        <Link to={Routes.app.user.notifications}
                                                              key={notification.id}
                                                              className={'dropdown-item ' + (notification.status === 'ACTIVE' ? 'unread' : '')}>
                                                            {notification.subject}
                                                        </Link>
                                                    );
                                                }) :
                                                <span style={{padding: '.25rem 1.5rem'}}>No active notifications</span>
                                            }
                                            <div className="dropdown-divider" />
                                            <Link to={Routes.app.user.notifications} className="dropdown-item">View all notifications</Link>
                                        </div>
                                    </li>
                                    <li className="list-inline-item pt-2">
                                        <UserDropdown />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            notifications: []
        };

        if (props.notifications) {
            initialState.notifications = props.notifications;
        }

        return initialState;
    }

}

export default Header;