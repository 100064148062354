import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";

// TODO: Lots here!
class MyCompany extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = {
            company: {
                name: '',
                website: ''
            }
        }
    }

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col">
                        <h2>My Company</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="settings_name">Company Name</label>
                            <input type="text"
                                   value={this.state.company.name}
                                   onChange={(e) => this._updateCompanyState({name: e.target.value})}
                                   name="settings_name" placeholder="SuiteGig" className="required form-control"
                                   id="settings_name" tabIndex={1} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="settings_website">Website</label>
                            <input type="text"
                                   value={this.state.company.website}
                                   onChange={(e) => this._updateCompanyState({website: e.target.value})}
                                   name="settings_website" placeholder="https://www.suitegig.com"
                                   className="required form-control" id="settings_website" tabIndex={2} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="settings_address">Address</label>
                            <input type="text"
                                   value={this.state.company.address}
                                   onChange={(e) => this._updateCompanyState({address: e.target.value})}
                                   name="settings_address"
                                   placeholder="123 Swanky Address, New York, NY 01122"
                                   className="required form-control" id="settings_address" tabIndex={3} />
                        </div>

                        <button className="btn btn-dk-green mt-3" tabIndex="7">Save</button>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Updates this React component's company state with the given value.
     *
     * @param value a nested field on this components `this.state.company` object
     * @private
     */
    _updateCompanyState(value) {
        this.setState({
            company: Object.assign({}, this.state.company, value)
        });
    }
}

export default MyCompany;