import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";

// TODO: Lots here!
class MyTeam extends SecureComponent {

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col-md-8">
                        <p>Understanding your team and how you work helps us figure out faster and smarter ways for you
                            to get things done.</p>
                        <p>Add your team structure below.
                            <i className="fas fa-info-circle" data-toggle="tooltip" data-placement="top"
                               title="We can also sync other teams in your company up for you. Ask about our Enterprise plans." />
                        </p>
                    </div>
                </div>
                <div className="row team_view">
                    <div className="col-12">
                        <ul className="list-inline">
                            <li className="list-inline-item dark">
                                <img src="/images/user_image.png" alt="" />
                                <span>Sergey Brin</span>
                            </li>
                            <li className="list-inline-item dark text-center text-white add_teammate">
                                <a href="#">
                                    <i className="fas fa-plus" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="list-inline">
                            <li className="list-inline-item dk-green">
                                <img src="/images/user_image.png" alt="" />
                                <span>Justin Ramedia</span>
                            </li>
                            <li className="list-inline-item dk-green text-center text-white add_teammate">
                                <a href="#">
                                    <i className="fas fa-plus" />
                                </a>
                            </li>
                        </ul>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <img src="/images/user_image.png" alt="" />
                                <span>UX Guy</span>
                            </li>
                            <li className="list-inline-item">
                                <img src="/images/user_image.png" alt="" />
                                <span>Design Guy</span>
                            </li>
                            <li className="list-inline-item">
                                <img src="/images/user_image.png" alt="" />
                                <span>Marketing Bro</span>
                            </li>
                            <li className="list-inline-item text-center text-white add_teammate">
                                <a href="#">
                                    <i className="fas fa-plus" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="list-inline">
                            <li className="list-inline-item dk-green">
                                <img src="/images/user_image.png" alt="" />
                                <span>Adam Childs</span>
                            </li>
                            <li className="list-inline-item dk-green text-center text-white add_teammate">
                                <a href="#">
                                    <i className="fas fa-plus" />
                                </a>
                            </li>
                        </ul>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <img src="/images/user_image.png" alt="" />
                                <span>Code Guy</span>
                            </li>
                            <li className="list-inline-item">
                                <img src="/images/user_image.png" alt="" />
                                <span>DB Guy</span>
                            </li>
                            <li className="list-inline-item">
                                <img src="/images/user_image.png" alt="" />
                                <span>Front-end Gal</span>
                            </li>
                            <li className="list-inline-item text-center text-white add_teammate">
                                <a href="#">
                                    <i className="fas fa-plus" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyTeam;