import React from "react";
import SecureComponent from "../../common/util/SecureComponent";
import CreateGig from "./CreateGig";
import ViewGig from "./ViewGig";
import queryString from "query-string";

/**
 * Displays the main Gig creation modal.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Gig extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        let params = queryString.parse(this.props.location.search);

        // Did a query parameter of 'id' come in? If so, show an existing Gig with the given id
        if (params.id) {
            return (
                <ViewGig id={params.id} key={params.id} {...this.props} />
            );
        }

        return (
            <CreateGig {...this.props} />
        );
    }

}

export default Gig;