import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import UserService from "../../../common/user/UserService";
import {updateProfileImage, updateUserName} from "../../../common/redux/actions";
import Routes from "../../../common/util/Routes";

/**
 * Represents the user dropdown that display in the site header when a user is authenticated.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class UserDropdown extends Component {

    constructor(props) {
        super(props);

        this._doLogout = this._doLogout.bind(this);

        this.state = {
            isUserAdmin: false
        };
    }

    componentDidMount() {
        UserService.getCurrentUser(response => this.props.updateUserName(response.name));
        UserService.getUserProfileImage(profileImage => this.props.updateProfileImage(profileImage));
        UserService.isCurrentUserAdministrator(result => {
            this.setState({
                isUserAdmin: result
            });
        });
    }

    /*
     * Note: As this is a shared component, stay away from react router elements (i.e. Link).
     */
    render() {
        let adminLink;
        if (this.state.isUserAdmin) {
            adminLink = <a className="dropdown-item" href={Routes.app.admin.dashboard}><strong>Admin</strong></a>
        }

        return(
            <div className="user-dropdown">
                <a href="#" className="dropdown-toggle" id="userDropdown" role="button"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="nav_avatar"
                          style={{backgroundImage: "url('" + this.props.user.profileImage.data + "')"}} />
                    {this.props.user.name}</a>
                <div className="dropdown-menu" aria-labelledby="userDropdown">
                    <a className="dropdown-item" href="#" data-toggle="modal"
                       data-target="#invite_modal">Invite team members</a>
                    <a href={Routes.app.user.settings.team} className="dropdown-item">Team/Roles</a>
                    <a className="dropdown-item" href="#">Permissions</a>
                    <a href={Routes.app.user.settings.profile} className="dropdown-item">Settings</a>
                    <a className="dropdown-item" href="#">Integrations</a>
                    <a className="dropdown-item" href="#">Help</a>

                    {adminLink}

                    <a className="dropdown-item border-top pt-2"
                       href="#"
                       onClick={this._doLogout}>Logout</a>
                </div>
            </div>
        );
    }

    /**
     * Logs out the currently authenticated user while also clearing any browser storage.
     */
    _doLogout() {
        localStorage.clear();
        sessionStorage.clear();

        window.location.href = Routes.app.logout;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserName: (name) => { dispatch(updateUserName(name)) },
        updateProfileImage: (data, filename, type) => { dispatch(updateProfileImage(data, filename, type)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
