import React, { Component } from "react";
import Routes from "../../../common/util/Routes";
import Cookies from "../../../common/util/Cookies";

/**
 * Displays a modal dialog to invite team members to SuiteGig.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class InvitationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            invitees: [{
                name: '',
                email: ''
            }],
            user: {
                name: ''
            },
            submissionResponse: false
        };
    }

    render() {
        return(
            <div className="modal fade" id="invite_modal" tabIndex="-1" role="dialog" aria-labelledby="invite_title"
                 aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content pt-3 pb-5 px-5">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times" />
                            </button>
                            <h1 id="invite_title">Invite your team</h1>
                        </div>
                        <div className="modal-body">
                            <p>Enter your team's email addresses below and we'll get them set up as part of your
                                team.</p>
                            <form className="validate">
                                <div id="invite_wrapper">
                                    <div className="row" id="invite_row">
                                        <div className="col">
                                            <div className="form-group">
                                                <p>Teammate's Name</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                Teammate's Email
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.invitees.map((invitee, index) => {
                                        return (
                                            <div className="row" id="invite_row" key={index}>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <input type="text"
                                                               value={this.state.invitees[index].name}
                                                               onChange={(e) => this._updateInviteeState(index, {name: e.target.value})}
                                                               name="invitation_name" placeholder="John Suite"
                                                               className="required form-control" id="invitation_name"
                                                               tabIndex={index + 1} />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <input type="text"
                                                               value={this.state.invitees[index].email}
                                                               onChange={(e) => this._updateInviteeState(index, {email: e.target.value})}
                                                               name="invitation_email" placeholder="john.suite@example.com"
                                                               className="required form-control" id="invitation_email"
                                                               tabIndex={index + 2} />
                                                    </div>
                                                </div>
                                                {index > 0 ?
                                                    (
                                                        <div className="col-sm-1 remove-invite" onClick={(e) => this._removeInvitee(index)}>
                                                            <i className="fas fa-times-circle"></i>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div className="col-sm-1" />
                                                    )
                                                }
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="text-small" id="add_invite" onClick={this._addTeammate.bind(this)}>
                                            <i className="fas fa-plus" /> Add another teammate
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-dk-green mt-3" onClick={this._sendInvites.bind(this)}>Send invite</button>

                                {this.state.submissionResponse}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @private
     */
    _addTeammate() {
        this.setState({
            invitees: [...this.state.invitees, {
                name: '',
                email: ''
            }]
        });
    }

    /**
     * Updates this React component's invitee list state with the given value.
     *
     * @param index the index of the element being updated
     * @param value a nested field on this components `this.state.invitees` object
     * @private
     */
    _updateInviteeState(index, value) {
        let invitee = Object.assign({}, this.state.invitees[index], value);

        let invitees = this.state.invitees;
        invitees[index] = invitee;

        this.setState({
            invitees: invitees
        });
    }

    /**
     * Removes the invitee at the given index.
     *
     * @param index the row number (index) of the invitee to remove
     * @private
     */
    _removeInvitee(index) {
        let invitees = [...this.state.invitees];
        if (!invitees) {
            console.warn("Cannot remove invitee from empty list.");

            return;
        }

        invitees.splice(index, 1);

        this.setState({
            invitees: invitees
        });
    }

    /**
     * Resets invitation modal fields.
     *
     * @private
     */
    _resetFields() {
        this.setState({
            invitees: [{
                name: '',
                email: ''
            }]
        });
    }

    /**
     * Sends an invitation email to all users listed in the modal.
     *
     * @private
     */
    _sendInvites(e) {
        e.preventDefault();

        console.log("Sending invites...");

        fetch(Routes.ajax.user.sendInvitations, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                invitees: this.state.invitees
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to send invitations: " + error);

            this._displayFormMessage("Something went wrong.");
        })
        .then(function(response) {
            if (!response) {
                console.log('No invitations sent');

                this._displayFormMessage("Something went wrong.");

                return;
            }

            if (response !== 'SUCCESS') {
                console.log('Unable to send invitations');

                this._displayFormMessage("Something went wrong.");

                return;
            }

            this._displayFormMessage("Success.");

            // Reset fields on success
            setTimeout(function() {
                this._resetFields();
            }.bind(this), 300);
        }.bind(this));
    }

    /**
     * Displays an form message.
     *
     * @private
     */
    _displayFormMessage(message) {
        this.setState({
            submissionResponse: (<p>{message}</p>)
        });
    }

}

export default InvitationModal;