import React  from "react";
import AdminComponent from "../../common/util/AdminComponent";

/**
 * Displays the SuiteGig Administrator view for notification management.
 *
 * @author Adam Childs
 */
class AdminNotifications extends AdminComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <h1>Admin - Alerts</h1>
            </div>
        );
    }
}

export default AdminNotifications;