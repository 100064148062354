import React from "react";
import AdminComponent from "../../../common/util/AdminComponent";
import Routes from "../../../common/util/Routes";
import Cookies from "../../../common/util/Cookies";

/**
 * Displays a modal dialog to invite team members to SuiteGig.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
export default class SendNotificationModal extends AdminComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    render() {
        return(
            <div className="modal fade" id="send_notification_modal" tabIndex="-1" role="dialog"
                 aria-labelledby="send_notification_title" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content pt-3 pb-5 px-5">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times" />
                            </button>
                            <h1 id="send_notification_title">Send a Notification</h1>
                        </div>
                        <div className="modal-body">
                            <p>Enter information below to send a notification/alert to all users. Notifications are
                                sent to users on a one minute interval. They may not appear immediately.</p>
                            <form className="validate">
                                <div id="send_notification_wrapper">
                                    <div className="row" id="send_notification_row">
                                        <div className="col">
                                            <div className="form-group">
                                                <p><strong>From</strong></p>
                                                <input type="text"
                                                       name="from"
                                                       value={this.state.from}
                                                       onChange={this._onChangeFrom.bind(this)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" id="send_notification_row">
                                        <div className="col">
                                            <div className="form-group">
                                                <p><strong>Subject</strong></p>
                                                <input type="text"
                                                       name="subject"
                                                       value={this.state.subject}
                                                       onChange={this._onChangeSubject.bind(this)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" id="send_notification_row">
                                        <div className="col">
                                            <div className="form-group">
                                                <p><strong>Content</strong></p>
                                                <textarea name="content"
                                                          value={this.state.content}
                                                          onChange={this._onChangeContent.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-dk-green mt-3"
                                        onClick={this._sendNotifications.bind(this)}>Send notification</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @param event
     * @private
     */
    _onChangeFrom(event) {
        this.setState({
            from: event.target.value
        });
    }

    /**
     *
     * @param event
     * @private
     */
    _onChangeSubject(event) {
        this.setState({
            subject: event.target.value
        });
    }

    /**
     *
     * @param event
     * @private
     */
    _onChangeContent(event) {
        this.setState({
            content: event.target.value
        });
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        return {
            from: '',
            subject: '',
            content: ''
        };
    }

    /**
     * Resets notification modal fields.
     *
     * @private
     */
    _resetFields() {
        this.setState({
            from: '',
            subject: '',
            content: ''
        });
    }

    /**
     *
     * @param event
     * @private
     */
    _sendNotifications(event) {
        event.preventDefault();

        fetch(Routes.ajax.admin.notifications, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                from: this.state.from,
                subject: this.state.subject,
                content: this.state.content
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to create notification: " + error);
        })
        .then(function(response) {
            if (!response) {
                console.log('No notification created');

                return;
            }

            if (response !== 'SUCCESS') {
                console.log('Unable to create notification');

                return;
            }

            this._resetFields();
        }.bind(this));
    }

}