import React from "react";
import SecureComponent from "../../common/util/SecureComponent";
import {Link} from "react-router-dom";
import Routes from "../../common/util/Routes";
import Table from "../component/table/Table";
import moment from "moment";

/**
 * Displays the main Gig archive page.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
export default class Archive extends SecureComponent {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            gigs: props.gigs
        };
    }

    render() {
        return (
            <div>
                <p>Archive</p>
                <Table
                    headers={['Gig name', 'Date created', 'Date started', 'Date completed', 'Assignee', 'Users', 'Tags']}
                    rows={
                        this.state.gigs
                            .filter((gig) => {
                                // Only show COMPLETED gigs
                                return gig.status === 'COMPLETED'
                            })
                            .map((gig, index) => {
                                return [
                                    <Link to={{
                                        pathname: Routes.app.gig.base,
                                        search: '?id=' + gig.id
                                    }}>{gig.title}</Link>,
                                    '', // TODO: date created
                                    moment.utc(gig.startDate).local().format('YYYY/MM/DD hh:mm A'),
                                    '', // TODO: date completed
                                    '', // TODO: assignee
                                    [], // TODO: shared with
                                    [] // TODO: tags
                                ]
                            })
                    }
                />
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            gigs: []
        };

        if (props.gigs) {
            initialState.gigs = props.gigs
        }

        return initialState;
    }

}