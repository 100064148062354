import React from "react";
import SecureComponent from "../../common/util/SecureComponent";
import Routes from "../../common/util/Routes";
import Cookies from "../../common/util/Cookies";
import {Link} from "react-router-dom";

/**
 * Displays an attachment on the gig view.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
export default class Attachment extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    render() {
        if (this.state.isAdd) {
            return (
                <div className="attachment add">
                    <input type="file" name="file" className="inputfile" id="file" onChange={this._onChange.bind(this)} />
                    <label htmlFor="file"><i className="fas fa-plus" /></label>
                </div>
            );
        }

        return (
            <Link className="attachment"
                  to={Routes.ajax.gig.attachment.download + this.state.attachment.id}
                  download
                  target="_blank">
                <i style={{display: 'block', color: '#FFFFFF', width: '100%', height: '100%'}}
                   className={"fas fa-" + this._determineAttachmentIcon(this.state.attachment.mimeType)} />
            </Link>
        );
    }

    /**
     * Given the mime type of an attachment, determines which icon should be displayed (by returning the classname of
     * the appropriate 'Font Awesome' icon).
     *
     * @param mimeType the mime type of the current attachment
     * @returns {string} the classname of a Font Awesome icon to display
     * @private
     */
    _determineAttachmentIcon(mimeType) {
        switch(mimeType) {
            // Audio
            case 'audio/aac':
            case 'audio/midi':
            case 'audio/x-midi':
            case 'audio/mpeg':
            case 'audio/ogg':
            case 'audio/opus':
            case 'audio/wav':
            case 'audio/webm':
                return 'file-audio';

            // Video
            case 'video/x-msvideo':
            case 'video/mpeg':
            case 'video/ogg':
            case 'video/mp2t':
            case 'video/webm':
            case 'video/3gpp':
            case 'video/3gpp2':
            case 'video/quicktime':
                return 'file-video';

            // Image
            case 'image/bmp':
            case 'image/jpeg':
            case 'image/png':
            case 'image/tiff':
            case 'image/gif':
            case 'image/svg+xml':
            case 'image/webp':
                return 'file-image';

            // Microsoft Office
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'text/csv':
                return 'file-excel';

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file-word';

            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'file-powerpoint';

            // Archive
            case 'application/x-freearc':
            case 'application/x-bzip':
            case 'application/x-bzip2':
            case 'application/gzip':
            case 'application/java-archive':
            case 'application/vnd.rar':
            case 'application/x-tar':
            case 'application/zip':
            case 'application/x-7z-compressed':
                return 'file-archive';

            // Code
            case 'text/css':
            case 'text/html':
            case 'text/javascript':
            case 'application/json':
            case 'application/x-sh':
            case 'application/xhtml+xml':
            case 'text/xml':
            case 'application/xml':
                return 'file-code';

            // Other
            case 'application/pdf':
                return 'file-pdf';

            default:
                return 'file-alt';
        }
    }

    /**
     *
     * @param props
     * @returns {{isAdd: (*|boolean)}}
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            isAdd: props.isAdd ? props.isAdd : false
        }

        if (props.attachment) {
            initialState.attachment = props.attachment;
        }

        if (props.gig) {
            initialState.gig = props.gig;
        }

        return initialState;
    }

    /**
     *
     * @param event
     * @private
     */
    _onChange(event) {
        console.log("File upload onChange");

        let file = event.target.files[0];
        let request = new FormData();
        request.append('file', file, file.name);
        request.append('metadata', JSON.stringify({
            gigId: this.props.gig.id
        }));

        // Save new Gig
        fetch(Routes.ajax.gig.attachment.upload, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken()
            },
            body: request
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to add new attachment: " + error);
        })
        .then(
            function(response) {
                console.log("New attachment added [" + response.id + "]");

                this.props.updateAttachmentList(response);
            }.bind(this),
            function(error) {
                // TODO: Show an error message
                console.error(error);
            }
        );
    }

}