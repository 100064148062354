import React, { Component } from "react";
import { Link } from "react-router-dom";
import Routes from "../../common/util/Routes";
import TeamService from "../user/team/TeamService";

/**
 * Builds the main app navigational component.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Navigation extends Component {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            gigs: props.gigs
        };
    }

    componentDidMount() {
        TeamService.getUserTeam(data => {
            this.setState({
                team: data
            });
        });
    }

    render() {
        return(
            <div className="col-md-3 mb-3 pt-3 bg-ghost-green main_nav main_nav_new">
                <Link to={Routes.app.dashboard}>
                    <img className="nav_logo" src="/static/images/SuiteGig_Logo_beta.svg" alt="Suite Gig" />
                </Link>
                <nav className="sidebar">
                    <div className="sidebar-sticky">
                        <ul className="nav flex-column">
                            <li className="nav-item active mt-3">
                                <a className="nav-link active" data-toggle="collapse" href="#gigs" role="button" aria-controls="gigs" aria-expanded="true">My Gigs</a>
                                <ul className="collapse multi-collapse show" id="gigs" aria-expanded="true" >
                                    <li>
                                        <Link to={Routes.app.gig.base} className="add add_gig">Add a gig</Link>
                                    </li>

                                    {// TODO: Handle nested gigs
                                        this.state.gigs
                                            .filter((gig) => {
                                                // Only show READY/IN_PROGRESS gigs
                                                return gig.status !== 'COMPLETED'
                                            })
                                            .map((gig, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to={{
                                                                pathname: Routes.app.gig.base,
                                                                search: '?id=' + gig.id
                                                            }}
                                                            draggable={true}
                                                            onDragStart={this._onDragStart.bind(this)}
                                                            onDragEnd={this._onDragEnd.bind(this)}
                                                            data-gig-id={gig.id}
                                                            data-gig-title={gig.title}
                                                        >{gig.title}</Link>
                                                    </li>
                                                )
                                            })
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="main_nav--bottom-nav">
                    <div className="row team_nav">
                        <div className="col">
                            <Link to={Routes.app.organization.teams.base} className="d-block">
                                Team
                            </Link>

                            {
                                this.state.team.members.map((member, index) => {
                                    return (
                                        <div key={index} className="team_user_image">
                                            <Link to={Routes.app.organization.teams.member + '?id=' + member.userId}>
                                                <div style={{backgroundImage: "url(/static/images/user_placeholder.png)"}} />
                                            </Link>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="row pl-md-4">
                        <div className="col">
                            <Link to={Routes.app.gig.archive}>
                                <i className="fas fa-archive" />
                            </Link>
                        </div>
                        <div className="col text-right">
                            <Link to={Routes.app.gig.base}>
                                <i className="fas fa-plus-circle" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            gigs: [],
            draggedGig: {},
            team: {
                id: 0,
                members: []
            }
        };

        if (props.gigs) {
            initialState.gigs = props.gigs
        }

        if (props.draggedGig) {
            initialState.draggedGig = props.draggedGig
        }

        if (props.team) {
            initialState.team = props.team
        }

        return initialState;
    }

    /**
     *
     * @param event
     * @private
     */
    _onDragStart(event) {
        console.log('onDragGig: ' + event);

        this.props.updateDraggedGig(event.target, 'DRAG_START');
    }

    /**
     *
     * @param event
     * @private
     */
    _onDragEnd(event) {
        console.log('onDragEnd: ' + event);

        this.props.updateDraggedGig(event.target, 'DRAG_END');
    }
}

export default Navigation;