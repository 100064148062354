import React  from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import SecureComponent from "../../common/util/SecureComponent";
import Routes from "../../common/util/Routes";
import Cookies from "../../common/util/Cookies";
import UserService from "../../common/user/UserService";
import Boards from "./Boards";

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

const DragAndDropCalendar = withDragAndDrop(Calendar);

/**
 * Displays the initial app dashboard, usually the first view displayed when signing in.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Dashboard extends SecureComponent {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);

        UserService.getCurrentUserHardRefresh(response => {
            if (!response.preferences) {
                response.preferences = [];
            }

            let calendarView = response.preferences
                .filter(preference => preference.name === 'CALENDAR_VIEW_PREFERENCE')[0].value
                .toLowerCase();

            this.setState({
                calendarView: calendarView
            });
        });
    }

    static getDerivedStateFromProps(props, state) {
        return {
            gigs: props.gigs
        };
    }

    render() {
        const localizer = momentLocalizer(moment);

        return(
            <div>
                <DragAndDropCalendar
                    events={
                        this.state.gigs.map((gig) => {
                            let dueDate = moment.utc(gig.dueDate).local().toDate();
                            let startDate = moment.utc(gig.startDate).local().toDate();

                            return {
                                start: startDate,
                                end: dueDate,
                                title: gig.title,
                                resource: { // This holds any dynamic information we want
                                    id: gig.id,
                                    status: gig.status
                                }
                            };
                        })
                    }
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor={ ({end}) => new Date(end.getTime() + 1000) }
                    eventPropGetter={
                        (event, start, end, isSelected) => {
                            let className = '';
                            if (event.resource.status === 'COMPLETED') {
                                className = 'completed';
                            }

                            return {
                                className: className,
                                style: {}
                            };
                        }
                    }
                    views={{
                        week: true,
                        month: true,
                        agenda: true,
                        boards: Boards
                    }}
                    messages={{ boards: 'Boards' }}
                    view={this.state.calendarView ? this.state.calendarView : 'month'}
                    defaultView={this.state.calendarView ? this.state.calendarView : 'month'}
                    onSelectEvent={this._handleClickedEvent.bind(this)}
                    onView={this._updateUserPreference.bind(this)}

                    draggableAccessor={(event) => true}
                    onDropFromOutside={this._handleDropFromOutside.bind(this)}
                    onDragOver={this._handleDragOver.bind(this)}
                    onEventDrop={this._handleEventDrop.bind(this)}
                    onEventResize={this._handleEventResize.bind(this)}
                    onSelectSlot={this._handleSelectSlot.bind(this)}
                />
            </div>
        );
    }

    /**
     *
     * @param event
     * @private
     */
    _handleDropFromOutside(event) {
        let startTime = event.start;
        let endTime = event.end;
        let allDay = event.allDay;

        console.log("onDropFromOutside: " + this.props.draggedGig.id + " - " + this.props.draggedGig.title);
    }

    /**
     *
     * @param event
     * @private
     */
    _handleDragOver(event) {
        console.log("onDragOver: " + event);
    }

    /**
     *
     * @param event
     * @private
     */
    _handleEventDrop(event) {
        console.log("onEventDrop: " + event);
    }

    /**
     *
     * @param event
     * @private
     */
    _handleEventResize(event) {
        console.log("onEventResize: " + event);
    }

    /**
     *
     * @param event
     * @private
     */
    _handleSelectSlot(event) {
        console.log("onSelectSlot: " + event);
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            gigs: [],
            draggedGig: {},
            calendarView: 'month'
        };

        if (props.gigs) {
            initialState.gigs = props.gigs
        }

        if (props.draggedGig) {
            initialState.draggedGig = props.draggedGig
        }

        return initialState;
    }

    /**
     *
     * @param event
     * @private
     */
    _handleClickedEvent(event) {
        console.log("Clicked event!");

        this.props.history.push(Routes.app.gig.base + '?id=' + event.resource.id);
    }

    /**
     * Saves the user's current dashboard view so that next time the page loads, they see that view.
     *
     * @param view the current dashboard calendar view
     * @private
     */
    _updateUserPreference(view) {
        if (!view) {
            console.warn('Unable to determine current view.');

            return;
        }

        console.log('View change: ' + view.toUpperCase());
        this.setState({
            calendarView: view
        });

        fetch(Routes.ajax.user.preferences, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                calendarView: view.toUpperCase()
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to update user preferences: " + error);
        })
        .then(function(response) {
            if (!response || response !== 'SUCCESS') {
                console.warn('Unable to save user calendar view preference.');

                return;
            }
        }.bind(this));
    }

}

export default Dashboard;