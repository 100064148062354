import React, { Component } from "react";
import UserService from "../user/UserService";

/**
 * Simple wrapper Component that verifies a user's JWT existence before displaying a component. If no JWT is found, the
 * user is redirected to the login page. The user will be redirected to the originally requested page after successfully
 * logging in.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class SecureComponent extends Component {

    constructor(props) {
        super(props);

        // Keep in the constructor as we want this to block rendering (for security reasons)
        if (!UserService.isUserAuthenticated()) {
            window.location = '/app/login.html?redirect=' + window.location.pathname;
        }
    }
}

export default SecureComponent;