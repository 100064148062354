import React  from "react";
import {Route, Switch} from "react-router-dom";
import SecureComponent from "../../common/util/SecureComponent";
import Settings from "./settings/Settings";
import NotFound from "../error/NotFound";
import Routes from "../../common/util/Routes";
import Welcome from "./signup/welcome/Welcome";
import Upgrade from "./signup/Upgrade";
import NotificationArchive from "./notifications/NotificationArchive";

/**
 * Entrypoint for "/app/user/**" pages, routing to the appropriate components based off path.
 *
 * @author Adam Childs
 */
class User extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Switch>
                    <Route path={Routes.app.user.settings.base} render={(props) => <Settings {...props} /> } />
                    <Route path={Routes.app.user.signup.welcome.base} render={(props) => <Welcome {...props} /> } />
                    <Route path={Routes.app.user.notifications} render={(props) => <NotificationArchive {...props} /> } />
                    <Route path={Routes.app.user.upgrade} render={(props) => <Upgrade {...props} /> } />
                    <Route component={NotFound}/>
                </Switch>
            </div>
        );
    }
}

export default User;