import React, { Component } from "react";

/**
 * Renders a table.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Table extends Component {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            rows: props.rows,
            headers: props.headers
        };
    }

    render() {
        return(
            <div>
                <table className="table">
                    <thead>
                        <tr>
                        {this.state.headers.map((header, index) => {
                            return (<th key={index}>{header}</th>);
                        })}
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.rows.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {row.map((column, columnIndex) => {
                                    let computedColumn;
                                    if (Array.isArray(column)) {
                                        computedColumn = column.join(', ')
                                    } else {
                                        computedColumn = column;
                                    }

                                    return (
                                        <td key={columnIndex}>{computedColumn}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            rows: [],
            headers: []
        };

        if (props.rows) {
            initialState.rows = props.rows
        }

        if (props.headers) {
            initialState.headers = props.headers
        }

        return initialState;
    }
}

export default Table;