import {
    LOAD_USER,
    UPDATE_USER_NAME,
    UPDATE_PROFILE_IMAGE,
    UPDATE_USER_PROFILE,
    LOAD_NOTIFICATION_COUNT,
    LOAD_USER_GIGS,
    UPDATE_DRAGGED_GIG
} from "./actions";

export const initialState = {
    gigs: [],
    notifications: [],
    user: {
        birthDate: '',
        company: '',
        emailAddress: '',
        id: '',
        location: '',
        name: '',
        phoneNumber: '',
        profileImage: {
            data: '',
            filename: '',
            type: ''
        },
        authorities: [],
        title: ''
    },
    draggedGig: {}
};

export default function SuiteGigApp(state = initialState, action) {
    switch (action.type) {
        case LOAD_USER:
            return Object.assign({}, state, {
                ...state,
                user: {
                    ...state.user,
                    birthDate: action.payload.birthDate,
                    company: action.payload.company,
                    emailAddress: action.payload.emailAddress,
                    id: action.payload.id,
                    location: action.payload.location,
                    name: action.payload.name,
                    phoneNumber: action.payload.phoneNumber,
                    authorities: action.payload.authorities,
                    title: action.payload.title
                }
            });

        case UPDATE_USER_PROFILE:
            return Object.assign({}, state, {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload.name,
                    emailAddress: action.payload.emailAddress,
                    location: action.payload.location,
                    company: action.payload.company,
                    title: action.payload.title,
                    phoneNumber: action.payload.phoneNumber
                }
            });

        case UPDATE_USER_NAME:
            return Object.assign({}, state, {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload
                }
            });

        case UPDATE_PROFILE_IMAGE:
            return Object.assign({}, state, {
                ...state,
                user: {
                    ...state.user,
                    profileImage: action.payload
                }
            });

        case LOAD_NOTIFICATION_COUNT:
            return Object.assign({}, state, {
                ...state,
                notifications: [] // TODO: ?
            });

        case LOAD_USER_GIGS:
            return Object.assign({}, state, {
                ...state,
                gigs: action.payload
            });

        case UPDATE_DRAGGED_GIG:
            return Object.assign({}, state, {
                ...state,
                draggedGig: {
                    ...state.draggedGig,
                    id: action.payload.id,
                    title: action.payload.title
                }
            });

        default:
            return state
    }
}
