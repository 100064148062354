import React  from "react";
import SecureComponent from "../../common/util/SecureComponent";
import {Link} from "react-router-dom";
import Routes from "../../common/util/Routes";

/**
 *
 */
class Boards extends SecureComponent {
    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            backlog: props.events.filter(event => event.resource.status === 'BACKLOG'),
            inProgress: props.events.filter(event => (event.resource.status === 'IN_PROGRESS' || event.resource.status === 'READY')),
            blocked: props.events.filter(event => event.resource.status === 'BLOCKED'),
            completed: props.events.filter(event => event.resource.status === 'COMPLETED')
        };
    }

    render() {
        return (
            <div className="row boards">
                <div className="col-md-6 col-lg-3">
                    <h4>Backlog</h4>
                    <div className="p-3 shadow h-100">
                        {this.state.backlog.map((item, index) => {
                            return (
                                <Link key={index} to={{
                                    pathname: Routes.app.gig.base,
                                    search: '?id=' + item.resource.id
                                }} className="gig backlog">{item.title}</Link>
                            )
                        })}
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <h4>In Progress</h4>
                    <div className="p-3 shadow h-100">
                        {this.state.inProgress.map((item, index) => {
                            return (
                                <Link key={index} to={{
                                    pathname: Routes.app.gig.base,
                                    search: '?id=' + item.resource.id
                                }} className="gig">{item.title}</Link>
                            )
                        })}
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <h4>Blocked</h4>
                    <div className="p-3 shadow h-100">
                        {this.state.blocked.map((item, index) => {
                            return (
                                <Link key={index} to={{
                                    pathname: Routes.app.gig.base,
                                    search: '?id=' + item.resource.id
                                }} className="gig">{item.title}</Link>
                            )
                        })}
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <h4>Completed</h4>
                    <div className="p-3 shadow h-100">
                        {this.state.completed.map((item, index) => {
                            return (
                                <Link key={index} to={{
                                    pathname: Routes.app.gig.base,
                                    search: '?id=' + item.resource.id
                                }} className="gig completed">{item.title}</Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    _getInitialState(props) {
        if (props.events) {
            return {
                backlog: props.events.filter(event => event.resource.status === 'BACKLOG'),
                inProgress: props.events.filter(event => (event.resource.status === 'IN_PROGRESS' || event.resource.status === 'READY')),
                blocked: props.events.filter(event => event.resource.status === 'BLOCKED'),
                completed: props.events.filter(event => event.resource.status === 'COMPLETED')
            }
        }

        return {
            backlog: [],
            inProgress: [],
            blocked: [],
            completed: []
        }
    }
}

Boards.range = date => {
    return [dates.startOf(date, 'year')]
}

Boards.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -1, 'year')

        case navigate.NEXT:
            return dates.add(date, 1, 'year')

        default:
            return date
    }
}

Boards.title = (date, { localizer }) => "Boards"

export default Boards;