import React  from "react";
import AdminComponent from "../../common/util/AdminComponent";
import {Link} from "react-router-dom";
import Routes from "../../common/util/Routes";
import SendNotificationModal from "../component/modal/SendNotificationModal";
import saveAs from 'file-saver';

/**
 * Displays the SuiteGig Administrator dashboard.
 *
 * @author Adam Childs
 */
class AdminDashboard extends AdminComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <h1>Admin</h1>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="shadow p-4">
                            <h2>Users</h2>
                            <p>Displays a list of all SuiteGig users with miscellaneous statistics for each user.</p>
                            <ul className="list-unstyled mt-4">
                                <li className="list-unstyled-item mt-4">
                                    <Link to={Routes.app.admin.users}>View all</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="shadow p-4">
                            <h2>Notifications</h2>
                            <p>Allows administrators to manually send notifications to all users.</p>
                            <ul className="list-unstyled mt-4">
                                <li className="list-unstyled-item mt-4">
                                    <a href="#"
                                       data-toggle="modal"
                                       data-target="#send_notification_modal">
                                        <i className="fas fa-plus" /> Send a new notification
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="shadow p-4">
                            <h2>SuiteGig Export</h2>
                            <p>Exports all SuiteGig data in JSON format.</p>
                            <ul className="list-unstyled mt-4">
                                <li className="list-unstyled-item mt-4">
                                    <button className={'btn btn-primary'}
                                            onClick={this._downloadAllData.bind(this)}>
                                        Download all data
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <SendNotificationModal />
            </div>
        );
    }

    /**
     *
     * @param e
     * @private
     */
    _downloadAllData(e) {
        e.preventDefault();

        fetch(Routes.ajax.admin.getAllData, {
            method: 'GET'
        })
        .then(response => response.blob())
        .catch(error => {
            console.error("Unable to retrieve users: " + error);
        })
        .then(blob => saveAs(blob, 'data.json'));
    }
}

export default AdminDashboard;