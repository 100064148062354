import React from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import moment from "moment";
import UserService from "../../../common/user/UserService";

/**
 * Displays comment activity for a Gig.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class CommentActivity extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        UserService.getUserProfileImageByUserId(this.state.user.id, image => {
            let user = this.state.user;
            user.profileImage = image;

            this.setState({
                user: user
            });
        });
    }

    static getDerivedStateFromProps(props, state) {
        return {
            comment: props.activity.comment ? props.activity.comment : '',
            createDate: props.activity.createDate ? props.activity.createDate : '',
            type: props.activity.type ? props.activity.type : '',
            user: {
                id: props.activity.user.id ? props.activity.user.id : '',
                name: props.activity.user.name ? props.activity.user.name : '',
                profileImage: state.user.profileImage ? state.user.profileImage :
                    (props.activity.user.profileImage ? props.activity.user.profileImage : UserService.getDefaultProfileImage())
            }
        };
    }

    render() {
        let comment = this._prepareComment(this.state.comment);

        return (
            <div className="gig_activity__event">
                <div className="gig_activity__user">
                    <img src={this.state.user.profileImage} alt="user image"
                         className="rounded-circle img-fluid" />
                </div>
                <div className="gig_activity__description">
                    <a href="#">{this.state.user.name}</a> added a comment to this gig.
                </div>
                <div className="gig_activity__timestamp">
                    {this.state.createDate ? moment.utc(this.state.createDate).local().fromNow() : ''}
                </div>
                <div className="gig_activity__comment elevation-2">
                    <pre dangerouslySetInnerHTML={{__html: comment}} />
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            comment: '',
            createDate: '',
            type: '',
            user: {
                id: '',
                name: '',
                profileImage: UserService.getDefaultProfileImage(),
            }
        };

        if (props.activity) {
            initialState.comment = props.activity.comment ? props.activity.comment : '';
            initialState.createDate = props.activity.createDate ? props.activity.createDate : '';
            initialState.type = props.activity.type ? props.activity.type : '';

            if (props.activity.user) {
                initialState.user.id = props.activity.user.id ? props.activity.user.id : '';
                initialState.user.name = props.activity.user.name ? props.activity.user.name : '';
            }
        }

        return initialState;
    }

    /**
     * Parses the given comment, preparing it for display. This method will render detected links as <a> tags, for
     * example.
     *
     * @param comment the comment to prepare for display
     * @private
     */
    _prepareComment(comment) {
        let expression = /(https:|http:|www\.)\S*/;
        let regex = new RegExp(expression, 'g');

        return comment.replace(regex, function(match, p1, offset, string) {
            return '<a href="' + match + '" target="_blank">' + match + '</a>';
        });
    }

}

export default CommentActivity;