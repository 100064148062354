import React  from "react";
import Cookies from "../../../common/util/Cookies";
import SecureComponent from "../../../common/util/SecureComponent";
import connect from "react-redux/es/connect/connect";
import Routes from "../../../common/util/Routes";

const VALIDATION_ERROR = {
    unknown: 'An unexpected error occurred. Please try again later.',
    invalidCurrentPassword: 'Invalid old password.',
    invalidNewPassword: 'Invalid new password.',
    mismatchedPasswords: 'New password and confirmation do not match.'
};

const SUCCESS_MESSAGE = 'Password successfully changed.';

/**
 * Handles both the view and functionality of the User Settings password update page.
 *
 * @author Adam Childs
 */
class Password extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = {
            currentPassword: '',
            newPassword: '',
            passwordConfirmation: '',
            formError: null,
            showSuccess: false
        }
    }

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col">
                        <h2>Password</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="oldPassword">Old Password</label>
                            <input type="password" value={this.state.currentPassword} onChange={(e) => {this.setState({currentPassword: e.target.value})}}
                                   className="form-control" id="oldPassword" placeholder="Password" tabIndex={1} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <input type="password" value={this.state.newPassword} onChange={(e) => {this.setState({newPassword: e.target.value})}}
                                   className="form-control" id="newPassword" placeholder="Password" tabIndex={2} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm New Password</label>
                            <input type="password" value={this.state.passwordConfirmation} onChange={(e) => {this.setState({passwordConfirmation: e.target.value})}}
                                   className="form-control" id="confirmPassword" placeholder="Password" tabIndex={3} />
                        </div>

                        <div style={this.state.formError ? {display: 'block'} : {display: 'none'}}>
                            {this.state.formError}
                        </div>

                        <div style={this.state.showSuccess ? {display: 'block'} : {display: 'none'}}>
                            {SUCCESS_MESSAGE}
                        </div>

                        <button type="submit" className="btn btn-primary" onClick={this._updateUser.bind(this)} tabIndex={4}>Save</button>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Attempts to update the user's password with values from the form fields. This method will execute simple FE
     * validation against the form values. If any validation errors occur, the UI will disallow submission to the
     * backend and an appropriate error message will be shown.
     */
    _updateUser() {
        // Validate
        let success = this._doValidate(this.state.currentPassword, this.state.newPassword, this.state.passwordConfirmation);
        if (!success) {
            return;
        }

        // Submit update request
        fetch(Routes.ajax.user.update.password, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.props.user.id,
                currentPassword: this.state.currentPassword,
                newPassword: this.state.newPassword,
                passwordConfirmation: this.state.passwordConfirmation
            })
        })
        .then(response => response.json())
        .then(
            function(data) {
                if (data === 'SUCCESS') {
                    this.setState({
                        currentPassword: '',
                        newPassword: '',
                        passwordConfirmation: '',
                        formError: null,
                        showSuccess: true
                    });
                } else {
                    let errorMessage = '';
                    switch (data) {
                        case 'UNKNOWN':
                            errorMessage = VALIDATION_ERROR.unknown;
                            break;
                        case 'INVALID_CURRENT_PASSWORD':
                            errorMessage = VALIDATION_ERROR.invalidCurrentPassword;
                            break;
                        case 'INVALID_NEW_PASSWORD':
                            errorMessage = VALIDATION_ERROR.invalidNewPassword;
                            break;
                        case 'MISMATCHED_PASSWORDS':
                            errorMessage = VALIDATION_ERROR.mismatchedPasswords;
                            break;

                        default:
                            errorMessage = VALIDATION_ERROR.unknown;
                            break;
                    }

                    this.setState({
                        formError: errorMessage,
                        showSuccess: false
                    });
                }
            }.bind(this),
            function(error) {
                this.setState({
                    formError: VALIDATION_ERROR.unknown,
                    showSuccess: false
                });
            }.bind(this)
        )
    }

    /**
     * Validates the given password fields to ensure they conform to SuiteGig password standards.
     *
     * @param currentPassword the user's current password
     * @param newPassword the user's prospective password
     * @param passwordConfirmation a confirmation of the user's prospective password
     * @returns {boolean} true if the given password are ok; false otherwise
     */
    _doValidate(currentPassword, newPassword, passwordConfirmation) {
        if (!currentPassword) {
            this.setState({
                formError: VALIDATION_ERROR.invalidCurrentPassword,
                showSuccess: false
            });

            return false;
        } else if (newPassword.length <= 0) {
            this.setState({
                formError: VALIDATION_ERROR.invalidNewPassword,
                showSuccess: false
            });

            return false;
        } else if (newPassword !== passwordConfirmation) {
            this.setState({
                formError: VALIDATION_ERROR.mismatchedPasswords,
                showSuccess: false
            });

            return false;
        }

        // All validations passed, so remove any form errors
        this.setState({formError: null});

        return true;
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

export default connect(mapStateToProps)(Password);