import React from "react";
import SecureComponent from "../../common/util/SecureComponent";
import Routes from "../../common/util/Routes";
import Cookies from "../../common/util/Cookies";
import CreatableSelect from "react-select/creatable";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#FFF',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12)',
        padding: '1rem'
    }),

    control: (provided, state) => {
        let selectedOption = state.getValue()[0];

        return {
            ...provided,
            borderRadius: '30px'
        }
    },

    clearIndicator: (provided, state) => ({
        ...provided,
        cursor: 'pointer'
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        cursor: 'pointer'
    }),

    option: (provided, state) => {
        return {
            ...provided,
            ...state.data.styles,
            borderRadius: '30px',
            margin: '0.5rem 0',
            color: '#2CBBAF',
            transition: 'all .15s ease-in-out'
        };
    },

    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
};

/**
 * Handles display and interaction with all tags on the gig view.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
export default class Tags extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        let tags = props.tags.filter(tag => {
            return tag.active
        }).map(tag => {
            return {
                value: tag.id,
                label: tag.content
            }
        });

        return {
            gig: props.gig,
            tags: tags
        };
    }

    render() {
        return (
            <div className="form-group tags">
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="h4">Tags</h3>
                        <CreatableSelect
                            styles={customStyles}
                            id={"tags"}
                            name={"tags"}
                            isClearable
                            isMulti
                            onChange={this._handleTagChange.bind(this)}
                            value={this.state.tags}
                            options={[]}
                        />
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @param props
     * @returns {{tags: []}}
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            gig: {},
            tags: []
        };

        if (props.tags) {
            initialState.tags = props.tags
        }

        if (props.gig) {
            initialState.gig = props.gig
        }

        return initialState;
    }

    /**
     *
     * @param newValue
     * @param actionMeta
     * @private
     */
    _handleTagChange(newValue, actionMeta) {
        switch(actionMeta.action) {
            case 'create-option':
            case 'select-option':
                this._addTag(actionMeta.option.label);
                break;
            case 'remove-value':
                this._removeTag(actionMeta.removedValue.value);
                break;
            case 'clear':
                this._removeAllTags();
                break;
            case 'pop-value':
                break; // Do not handle delete/backspace
            default:
                console.warn('Unrecognized Select action: ' + actionMeta);
                break;
        }
    }

    /**
     * Adds a new tag to the current gig.
     *
     * @param tag the tag to add
     * @private
     */
    _addTag(tag) {
        if (!tag) {
            console.warn('Unable to add new tag because it is empty.');

            return;
        }

        console.log('Adding new tag [' + tag + '] to gig [' + this.state.gig.id + ']');

        // Add new tag to gig
        fetch(Routes.ajax.gig.tag.add, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gigId: this.state.gig.id,
                tag: tag
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to add new tag: " + error);
        })
        .then(
            function(response) {
                console.log("New tag added [" + response.id + "]");

                this.props.updateTagList(response);
            }.bind(this),
            function(error) {
                console.error("Unable to add new tag: " + error);
            }
        );
    }

    /**
     * Removes a single tag from the current gig.
     *
     * @param tag the unique identifier (within a single gig) of the tag to remove
     * @private
     */
    _removeTag(tag) {
        if (!tag) {
            console.warn('Unable to remove tag because it is empty.');

            return;
        }

        console.log('Removing tag: ' + tag);

        // Remove tag from gig
        fetch(Routes.ajax.gig.tag.remove, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gigId: this.state.gig.id,
                tagId: tag
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to remove tag [" + tag + "]: " + error);
        })
        .then(
            function(response) {
                if (response !== 'SUCCESS') {
                    console.log("Unable to remove tag [" + tag + "]");

                    return;
                }

                this.props.setTagInactive(tag);

                console.log("Tag removed [" + tag + "]");
            }.bind(this),
            function(error) {
                console.error("Unable to remove tag [" + tag + "]: " + error);
            }
        );
    }

    /**
     * Removes all tags from the current gig.
     *
     * @private
     */
    _removeAllTags() {
        console.log('Clearing all tags.');

        // Remove all tags from the gig
        fetch(Routes.ajax.gig.tag.removeAll, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gigId: this.state.gig.id
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to remove all tags from gig [" + this.state.gig.id + "]: " + error);
        })
        .then(
            function(response) {
                if (response !== 'SUCCESS') {
                    console.log("Unable to remove all tags from gig [" + this.state.gig.id + "]");

                    return;
                }

                this.props.setAllTagsInactive();
            }.bind(this),
            function(error) {
                console.error("Unable to remove all tags from gig [" + this.state.gig.id + "]: " + error);
            }.bind(this)
        );
    }

}