import React  from "react";
import AdminComponent from "../../common/util/AdminComponent";
import Routes from "../../common/util/Routes";
import moment from "moment";
import queryString from "query-string";

/**
 * Displays the SuiteGig Administrator view for user management.
 *
 * @author Adam Childs
 */
class AdminUsers extends AdminComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);

        this._getAllUsers(function(users) {
            this.setState({
                users: users
            })
        }.bind(this));
    }

    render() {
        let params = queryString.parse(this.props.location.search);

        if (params.userId) {
            return (
                <div>Single user</div>
            )
        }

        return(
            <div>
                <h1>Admin - Users</h1>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Email Address</th>
                            <th>Name</th>
                            <th>Account Creation Date</th>
                            <th>Last Login Time</th>
                            <th>Last Activity Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td>{user.id}</td>
                                    <td><a href={'mailto:' + user.emailAddress} target='_blank'>{user.emailAddress}</a></td>
                                    <td>{user.firstName}</td>
                                    <td>{moment.utc(user.createDate).local().format('MM/DD/YYYY HH:mm:ss')}</td>
                                    <td>{moment.utc(user.lastLoginTime).local().format('MM/DD/YYYY HH:mm:ss')}</td>
                                    <td>{moment.utc(user.lastActivityTime).local().format('MM/DD/YYYY HH:mm:ss')}</td>
                                </tr>
                            )}
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            users: []
        };

        if (props.users) {
            initialState.users = props.users
        }

        return initialState;
    }

    /**
     *
     * @private
     */
    _getAllUsers(callback) {
        fetch(Routes.ajax.admin.getAllUsers, {
            method: 'GET'
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to retrieve users: " + error);
        })
        .then(function(response) {
            if (!response) {
                console.log('No users found');

                return;
            }

            callback(response);
        });
    }

}

export default AdminUsers;