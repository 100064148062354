import React from "react";
import SecureComponent from "../common/util/SecureComponent";
import Navigation from "./component/Navigation";
import Header from "./component/header/Header";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import NotFound from "./error/NotFound";
import connect from "react-redux/es/connect/connect";
import {loadUserGigs, loadUser, updateProfileImage, updateDraggedGig} from "../common/redux/actions";
import Modals from "./component/modal/Modals";
import UserService from "../common/user/UserService";
import User from "./user/User";
import Routes from "../common/util/Routes";
import Gig from "./gig/Gig";
import Archive from "./gig/Archive";
import Admin from "./admin/Admin";
import Cookies from "../common/util/Cookies";
import Organization from "./organization/Organization";
import UnreadNotifications from "./user/notifications/UnreadNotifications";

/**
 * Entry-point for SuiteGig's web application.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class App extends SecureComponent {

    constructor(props) {
        super(props);

        this._notificationTimeout = 60000; // 60 seconds

        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        UserService.getCurrentUser(response => this.props.loadUser(
            response.birthDate,
            response.company,
            response.emailAddress,
            response.id,
            response.location,
            response.name,
            response.phone,
            response.authorities,
            response.title,
            response.preferences)
        );
        UserService.getUserProfileImage(data => this.props.updateProfileImage(data, '', ''));
        UserService.getUserGigs(data => this.props.loadUserGigs(data));

        // Immediately pull active notifications
        this._getActiveNotifications();

        // Then, start polling every 'this._timeout' interval for new notifications
        this.notificationTimer = setInterval(this._getActiveNotifications.bind(this), this._notificationTimeout);
    }

    componentWillUnmount() {
        // Stop polling for notifications
        clearInterval(this.notificationTimer);
        this.notificationTimer = null;
    }

    render() {
        return (
            <div>
                <section className="pt-0">
                    <div className="container-fluid">
                        <div className="row">
                            <Navigation gigs={this.props.gigs} updateDraggedGig={this.props.updateDraggedGig} />

                            <div className="main_app col-md-9 offset-md-3 ml-sm-auto p-md-4">
                                <Header notifications={this.state.notifications.slice(0, 5)} />

                                <UnreadNotifications notifications={this.state.notifications}
                                                     archiveNotification={this._archiveNotification.bind(this)} />

                                <Switch>
                                    <Route exact path={Routes.app.dashboard} render={(props) =>
                                        <Dashboard gigs={this.props.gigs} history={this.props.history} draggedGig={this.props.draggedGig} />
                                    } />
                                    <Route exact path={Routes.app.gig.base} render={(props) =>
                                        <Gig loadUserGigs={this.props.loadUserGigs} {...props} />
                                    } />
                                    <Route exact path={Routes.app.gig.archive} render={(props) =>
                                        <Archive gigs={this.props.gigs} />
                                    } />
                                    <Route path={Routes.app.user.base} render={(props) => <User {...props} />} />
                                    <Route path={Routes.app.organization.base} render={(props) => <Organization {...props} />} />
                                    <Route path={Routes.app.admin.base} render={(props) => <Admin {...props} />} />
                                    <Route component={NotFound}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </section>

                <Modals />
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            notifications: [],
            gigs: [],
            draggedGig: {}
        };

        if (props.notifications) {
            initialState.notifications = props.notifications;
        }

        if (props.gigs) {
            initialState.gigs = props.gigs;
        }

        if (props.draggedGig) {
            initialState.draggedGig = props.draggedGig;
        }

        return initialState;
    }

    /**
     * Retrieve the latest unread notifications from the backend for the current user.
     *
     * @private
     */
    _getActiveNotifications() {
        console.debug("Retrieving user notifications...");

        // Fetch and then setState
        fetch(Routes.ajax.user.notifications, {
            method: 'GET'
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to retrieve user notifications: " + error);
        })
        .then(function(notifications) {
            if (!notifications) {
                console.warn("Found no notifications, or backend is temporarily unavailable.");

                return;
            }

            console.debug("Found [" + notifications.length + "] notifications.");

            this.setState({
                notifications: notifications
            })
        }.bind(this));
    }

    /**
     * Closes a notification and archives it.
     *
     * @param notificationId the id of the notification to remove
     * @private
     */
    _archiveNotification(notificationId) {
        let index = this.state.notifications.findIndex(notification => notification.id === notificationId);
        if (index < 0) {
            console.warn("Unable to find notification to close [" + notificationId + "]");
            return;
        }

        let notification = this.state.notifications[index];

        // Archive the notification
        fetch(Routes.ajax.user.notifications + '/' + notification.id + '/archive', {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to archive notification: " + error);
        })
        .then(function(result) {
            if (result !== 'SUCCESS') {
                console.log('Unable to archive notification.');
            }
        }.bind(this));

        // Remove the notification from the UI
        let notifications = [...this.state.notifications];
        notifications.splice(index, 1);

        this.setState({
            notifications: notifications
        });
    }

}

const mapStateToProps = (state) => {
    return {
        gigs: state.gigs,
        draggedGig: state.draggedGig,
        notifications: state.notifications,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadUserGigs: (gigs) => {
            dispatch(loadUserGigs(gigs))
        },
        loadUser: (birthDate, company, emailAddress, id, location, name, phoneNumber, authorities, title, preferences) => {
            dispatch(loadUser(birthDate, company, emailAddress, id, location, name, phoneNumber, authorities, title, preferences))
        },
        updateProfileImage: (data, filename, type) => {
            dispatch(updateProfileImage(data, filename, type))
        },
        updateDraggedGig: (target, state) => {
            dispatch(updateDraggedGig(target, state))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);