import React  from "react";
import SettingsNavigation from "./SettingsNavigation";
import MyProfile from "./MyProfile";
import MyTeam from "./MyTeam";
import {Route, Switch} from "react-router-dom";
import NotFound from "../../error/NotFound";
import MyCompany from "./MyCompany";
import Password from "./Password";
import SecureComponent from "../../../common/util/SecureComponent";
import Routes from "../../../common/util/Routes";

/**
 * Main entrypoint for the user settings pages.
 *
 * @author Adam Childs
 */
class Settings extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <SettingsNavigation location={this.props.location} />

                <Switch>
                    <Route exact path={Routes.app.user.settings.profile}
                           render={(props) => <MyProfile {...props} /> }
                    />
                    <Route exact path={Routes.app.user.settings.team} component={MyTeam} />
                    <Route exact path={Routes.app.user.settings.company} component={MyCompany} />
                    <Route exact path={Routes.app.user.settings.password}
                           render={(props) => <Password {...props} /> }
                    />
                    <Route component={NotFound} />
                </Switch>
            </div>
        );
    }
}

export default Settings;