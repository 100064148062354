/*
 * Action Types
 */

// User settings
export const LOAD_USER = 'LOAD_USER';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const LOAD_NOTIFICATION_COUNT = 'LOAD_NOTIFICATION_COUNT';
export const LOAD_USER_GIGS = 'LOAD_USER_GIGS';
export const UPDATE_DRAGGED_GIG = 'UPDATE_DRAGGED_GIG';

/*
 * Action Creators
 */
export function loadUser(birthDate, company, emailAddress, id, location, name, phoneNumber, authorities, title, preferences) {
    return {
        type: LOAD_USER,
        payload: {
            birthDate: parse(birthDate),
            company: parse(company),
            emailAddress: parse(emailAddress),
            id: parse(id),
            location: parse(location),
            name: parse(name),
            phoneNumber: parse(phoneNumber),
            authorities: authorities,
            title: parse(title),
            preferences: preferences
        }
    }
}

export function updateUserProfile(name, emailAddress, location, company, title, phoneNumber) {
    return {
        type: UPDATE_USER_PROFILE,
        payload: {
            name: parse(name),
            emailAddress: parse(emailAddress),
            location: parse(location),
            company: parse(company),
            title: parse(title),
            phoneNumber: parse(phoneNumber)
        }
    }
}

export function updateUserName(name) {
    return {
        type: UPDATE_USER_NAME,
        payload: parse(name)
    }
}

export function updateProfileImage(base64Image, filename, type) {
    return {
        type: UPDATE_PROFILE_IMAGE,
        payload: {
            data: parse(base64Image),
            filename: parse(filename),
            type: parse(type)
        }
    }
}

export function loadUserGigs(gigs) {
    return {
        type: LOAD_USER_GIGS,
        payload: gigs
    }
}

export function updateDraggedGig(target, state) {
    return {
        type: UPDATE_DRAGGED_GIG,
        payload: {
            id: state === 'DRAG_START' ? target.getAttribute('data-gig-id') : null,
            title: state === 'DRAG_START' ? target.getAttribute('data-gig-title') : null
        }
    }
}

/*
 * Supporting functions
 */
function parse(value) {
    return (value ? value : '');
}