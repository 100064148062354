import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import TeamService from "../../user/team/TeamService";

/**
 * Entrypoint for "/app/user/**" pages, routing to the appropriate components based off path.
 *
 * @author Adam Childs
 */
export default class Teams extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        TeamService.getUserTeam(data => {
            this.setState({
                team: data
            });
        });
    }

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col">
                        <h2>My Team</h2>
                        <ul>
                            {this.state.team.members.map((member, index) => {
                                let profileImageUrl = location.protocol + '//' + location.host + member.profileImage;
                                let backgroundImage = 'url(data:image/jpeg;base64,' + member.profileImage.data + ')';

                                return (
                                    <div className="team_user" key={index}>
                                        <div className="team_user_image">
                                            <div style={{backgroundImage: backgroundImage}} />
                                        </div>
                                        <div className="team_user_details">
                                            {member.name} <span>{member.status}</span>
                                            <div className="team_user_email">
                                                <a href={member.emailAddress}>{member.emailAddress}</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </ul>
                        <div>
                            <a className="dropdown-item" href="#" data-toggle="modal"
                               data-target="#invite_modal"><i className="fas fa-plus-circle" /> Add a team member</a>
                        </div>
                    </div>
                    <div className="col">
                        <h2>Org chart</h2>
                        <p>Understanding your team and how you work helps us figure out faster and smarter ways for you to get things done.</p>
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @param props
     * @returns {{teamId: number}|{teamId: (number|*)}}
     * @private
     */
    _getInitialState(props) {
        if (props.team) {
            return {
                team: props.team
            }
        }

        return {
            team: {
                id: 0,
                members: []
            }
        }
    }
}