import React, { Component } from "react";
import InformationalNotification from "./InformationalNotification";
import ActionableNotification from "./ActionableNotification";

/**
 * Displays a user's unread notifications.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class UnreadNotifications extends Component {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            notifications: props.notifications
        };
    }

    render() {
        return(
            <div className="toast-notifications">
                {this.state.notifications
                    .filter(notification => notification.status === 'UNREAD')
                    .map((notification, index) => {
                        let notificationType = notification['@type'];
                        if (notificationType === 'InformationalNotification') {
                            return (
                                <InformationalNotification
                                    key={notification.id}
                                    notification={notification}
                                    close={() => this.props.archiveNotification(notification.id)} />
                            )
                        } else if (notificationType === 'ActionableNotification') {
                            return (
                                <ActionableNotification
                                    key={notification.id}
                                    notification={notification}
                                    close={() => this.props.archiveNotification(notification.id)} />
                            )
                        } else {
                            console.warn("Unhandled notification type [" + notificationType + "] for " +
                                "notification with ID [" + notification.id + "].");
                        }
                    })}
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            notifications: []
        };

        if (props.notifications) {
            initialState.notifications = props.notifications;
        }

        return initialState;
    }

}

export default UnreadNotifications;