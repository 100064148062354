import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import NumberFormat from 'react-number-format';
import {LOCAL_STORAGE_KEYS} from "../../../common/user/UserService";
import Cookies from "../../../common/util/Cookies";
import {updateProfileImage} from "../../../common/redux/actions";
import connect from "react-redux/es/connect/connect";
import Routes from "../../../common/util/Routes";

/**
 * Handles both the view and functionality of the User Settings 'My Profile' update page.
 *
 * @author Adam Childs
 */
class MyProfile extends SecureComponent {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = this._getInitialState(props);
    }

    // TODO: Determine if this is the best way to set initial form state w/ redux
    // TODO: Replace this as it's deprecated
    UNSAFE_componentWillReceiveProps(nextProps) {
        this._updateUserState({
            company: nextProps.user.company,
            emailAddress: nextProps.user.emailAddress,
            id: nextProps.user.id,
            location: nextProps.user.location,
            name: nextProps.user.name,
            phoneNumber: nextProps.user.phoneNumber,
            profileImage: nextProps.user.profileImage,
            title: nextProps.user.title
        });
    }

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col">
                        <h2>My Profile</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
                                       onChange={this._onSelectNewProfileImage.bind(this)} />
                                <label htmlFor="imageUpload" />
                            </div>
                            <div className="avatar-preview">
                                <img id="imagePreview"
                                     src={this.props.user.profileImage.data}
                                     alt="user image"
                                     className="img-fluid user_image"
                                     style={{borderRadius: 100 + '%'}} />
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="settings_name">Name</label>
                            <input type="text"
                                   value={this.state.user.name}
                                   onChange={(e) => this._updateUserState({name: e.target.value})}
                                   name="settings_name" placeholder="John Suite" className="required form-control"
                                   id="settings_name" tabIndex={1} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="settings_email">Email Address</label>
                            <input type="email"
                                   value={this.state.user.emailAddress}
                                   onChange={(e) => this._updateUserState({emailAddress: e.target.value})}
                                   name="settings_email" placeholder="email@website.com"
                                   className="required form-control" id="settings_email" tabIndex={3} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="settings_location">Location</label>
                            <input type="text"
                                   value={this.state.user.location}
                                   onChange={(e) => this._updateUserState({location: e.target.value})}
                                   name="location" placeholder="Tampa, FL" className="form-control"
                                   id="settings_location" tabIndex={5} />
                            <a className="form_inside_btn" href="#">
                                <i className="fas fa-map-marker-alt" />
                            </a>
                        </div>

                        <button className="btn btn-dk-green mt-3" onClick={this._updateUser.bind(this)} tabIndex={7}>Save</button>
                    </div>

                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="settings_company">Company</label>
                            <input type="text"
                                   value={this.state.user.company}
                                   onChange={(e) => this._updateUserState({company: e.target.value})}
                                   name="settings_company" placeholder="SuiteGig, Inc."
                                   className="required form-control" id="settings_company" tabIndex={2} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="settings_title">Title</label>
                            <input type="email"
                                   value={this.state.user.title}
                                   onChange={(e) => this._updateUserState({title: e.target.value})}
                                   name="settings_title" placeholder="Software Engineer"
                                   className="required form-control" id="settings_title" tabIndex={4} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="settings_phone">Phone</label>
                            <NumberFormat
                                type="text"
                                className="form-control"
                                name="settings_phone"
                                id="settings_phone"
                                tabIndex={6}
                                format="(###) ### - ####"
                                mask=""
                                placeholder="(###) ### - ####"
                                onValueChange={(e) => this._updateUserState({phoneNumber: e.value})}
                                value={this.state.user.phoneNumber}
                            />

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            formError: null,
            showSuccess: false,
            submitEnabled: false,
            user: {
                company: '',
                emailAddress: '',
                id: '',
                location: '',
                name: '',
                phoneNumber: '',
                profileImage: '', // TODO: Default to svg with user's initials?
                title: ''
            }
        };

        if (props.user) {
            initialState.user = {
                company: props.user.company,
                emailAddress: props.user.emailAddress,
                id: props.user.id,
                location: props.user.location,
                name: props.user.name,
                phoneNumber: props.user.phoneNumber,
                profileImage: props.user.profileImage.data,
                title: props.user.title
            }
        }

        return initialState;
    }

    /**
     * Updates this React component's user state with the given value.
     *
     * @param value a nested field on this components `this.state.user` object
     * @private
     */
    _updateUserState(value) {
        this.setState({
            user: Object.assign({}, this.state.user, value)
        });
    }

    /**
     * Updates the currently selected profile image.
     *
     * @private
     */
    _onSelectNewProfileImage(event) {
        let file = event.target.files[0];
        let fr = new FileReader();
        fr.onload = () => this.props.updateProfileImage(fr.result, file.name, file.type);
        fr.readAsDataURL(file);
    }

    /**
     * Attempts to persist the user profile changes.
     *
     * @private
     */
    _updateUser() {
        // Validate
        let success = this._doValidate(this.state.user);
        if (!success) {
            // TODO: Show an appropriate error message
            return;
        }

        // Save user
        fetch(Routes.ajax.user.update.profile, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: this._getUserUpdateRequest(this.state.user)
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to update user: " + error);
        })
        .then(
            function(response) {
                if (response === 'SUCCESS') {
                    // Update the profile image in sessionStorage if a new one was selected
                    if (this.props.user.profileImage.data) {
                        sessionStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_IMAGE, this.props.user.profileImage.data);
                    }

                    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
                    // TODO: Show success message
                } else {
                    // TODO: Show error message
                    console.error(response);
                }
            }.bind(this),
            function(error) {
                // TODO: Show an error message
                console.error(error);
            }
        )
    }

    /**
     *
     * @param user
     * @returns {boolean}
     * @private
     */
    _doValidate(user) {
        // All validations passed, so remove any form errors
        this.setState({formError: null});

        return true;
    }

    /**
     *
     * @returns {string}
     * @private
     */
    _getUserUpdateRequest(user) {
        if (user.profileImage.data) {
            return JSON.stringify({
                userId: user.id,
                profileImage: {
                    userId: user.id,
                    originalFileName: user.profileImage.filename,
                    data: user.profileImage.data
                },
                name: user.name,
                emailAddress: user.emailAddress,
                location: user.location,
                company: user.company,
                title: user.title,
                phoneNumber: user.phoneNumber
            });
        }

        return JSON.stringify({
            userId: user.id,
            name: user.name,
            emailAddress: user.emailAddress,
            location: user.location,
            company: user.company,
            title: user.title,
            phoneNumber: user.phoneNumber
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfileImage: (data, filename, type) => { dispatch(updateProfileImage(data, filename, type)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);