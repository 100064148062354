import React from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import AttachmentActivity from "./AttachmentActivity";
import CommentActivity from "./CommentActivity";
import GigActivity from "./GigActivity";
import TagActivity from "./TagActivity";

/**
 * Displays the main Gig creation modal.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Activity extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            gig: props.gig,
            user: props.user
        };
    }

    render() {
        let activity = this._getActivity();

        return (
            <div className="gig_activity">
                {activity
                    .sort(function(activityOne, activityTwo) {
                        if (activityOne.createDate < activityTwo.createDate) {
                            return -1;
                        } else if (activityOne.createDate > activityTwo.createDate) {
                            return 1;
                        }

                        return 0;
                    })
                    .map((activity, index) => {
                        switch(activity.type) {
                            case 'ATTACHMENT':
                                return (<AttachmentActivity activity={activity} key={index} />);

                            case 'COMMENT':
                                return (<CommentActivity activity={activity} key={index} />);

                            case 'GIG':
                                return (<GigActivity activity={activity} key={index} />);

                            case 'TAG':
                                return (<TagActivity activity={activity} key={index} />);

                            default: {
                                console.warn('Unhandled activity type: [' + activity.type + ']');

                                return (
                                    <GigActivity activity={activity} key={index} />
                                );
                            }
                        }
                    })
                }
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            activity: [],
            gig: {},
            user: {
                profileImage: ''
            }
        };

        if (props.activity) {
            initialState.activity = props.activity;
        }

        if (props.gig) {
            initialState.gig = props.gig;
        }

        if (props.user) {
            initialState.user = props.user;
        }

        return initialState;
    }

    /**
     *
     * @returns {[{subType: string, type: string, user: {name: *, profileImage: *}, createDate: *}]}
     * @private
     */
    _getActivity() {
        // TODO: Pull activity from backend service?
        let activity = [
            {
                type: 'GIG',
                subType: 'CREATED',
                user: {
                    id: this.state.gig.createdBy.id,
                    name: this.state.gig.createdBy.name
                },
                createDate: this.state.gig.createDate
            }
        ];

        this.state.gig.comments.map(comment => {
            activity.push({
                type: 'COMMENT',
                user: {
                    id: comment.createdBy.id,
                    name: comment.createdBy.name
                },
                comment: comment.content,
                createDate: comment.creationDate
            });
        });

        // TODO: This currently only displays active tags in activity. We want to also display activity for tags that
        //  have been removed... That's still activity.
        this.state.gig.tags.map(tag => {
            activity.push({
                type: 'TAG',
                user: {
                    id: tag.createdBy.id,
                    name: tag.createdBy.name
                },
                tag: {
                    title: tag.content
                },
                createDate: tag.creationDate
            })
        });

        this.state.gig.attachments.map(attachment => {
            activity.push({
                type: 'ATTACHMENT',
                user: {
                    id: (attachment.createdBy ? attachment.createdBy.id : ''),
                    name: (attachment.createdBy ? attachment.createdBy.name : '')
                },
                attachment: {
                    filename: attachment.name,
                    id: attachment.id
                },
                createDate: attachment.creationDate
            })
        });

        return activity;
    }

}

export default Activity;