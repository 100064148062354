import JWT from "../util/JWT";
import DataUtil from "../util/DataUtil";
import Routes from "../util/Routes";
import moment from "moment";
import queryString from "query-string";
import Cookies from "../util/Cookies";

export const LOCAL_STORAGE_KEYS = {
    USER: 'suitegig_user',
    PROFILE_IMAGE: 'suitegig_user_profile_image'
};

/**
 * Provides utility functions for operating the currently authenticated SuiteGig user.
 *
 * @author Adam Childs
 */
class UserService {

    /**
     * Determines is a user is currently authenticated.
     *
     * @returns {boolean} true if an active user authentication session exists; false otherwise
     */
    static isUserAuthenticated() {
        let token = JWT.getAuthenticationToken();
        if (token === null || token === undefined) {
            return false;
        }

        let expiration = moment.utc(token.expiration).local().valueOf();
        let currentTime = new Date().getTime();

        return expiration > currentTime;
    }

    /**
     *
     * @param callback
     */
    static getCurrentUserHardRefresh(callback) {
        sessionStorage.removeItem(LOCAL_STORAGE_KEYS.USER);

        this.getCurrentUser(callback);
    }

    /**
     * Retrieves any relevant claim information from the JWT related to the authenticated user, calling the given
     * {@code callback} with the user as input to the callback.
     *
     * This function will also store the user in sessionStorage for fast future access.
     */
    // TODO: Should this use redux as well?
    static getCurrentUser(callback) {
        let token = JWT.getAuthenticationToken();
        if (!token) {
            console.log('User is not authenticated.');
            sessionStorage.clear();

            return null;
        }

        // Do an API lookup for user information if it's not in local storage
        let user = sessionStorage.getItem(LOCAL_STORAGE_KEYS.USER);
        if (!user) {
            sessionStorage.clear();

            fetch(Routes.ajax.user.getCurrentUser, {
                method: 'GET'
            })
            .then(response => response.json())
            .then(result => {
                sessionStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(result));

                callback(result);
            });

            return;
        }

        callback(JSON.parse(user));
    }

    /**
     * Specifies whether or not the current user is an administrator.
     *
     * @returns {boolean}
     */
    static isCurrentUserAdministrator(callback) {
        // Do an API lookup for user information if it's not in local storage
        this.getCurrentUser(user => {
            callback(user.authorities.indexOf("ADMINISTRATOR") > -1);
        });
    }

    /**
     * Retrieves the current user's profile image, if one exists. If found, stores the image in sessionStorage for
     * fast future retrieval.
     *
     * @param callback executed callback with the users profile image as input
     */
    static getUserProfileImage(callback) {
        // Check localStorage for user's image first
        let profileImage = sessionStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_IMAGE);
        if (!profileImage) {
            fetch(Routes.ajax.user.getProfileImage, {
                method: 'GET'
            })
            .then(response => response.json())
            .catch(message => {
                console.warn("Unable to retrieve profile image: " + message);

                let image = this.getDefaultProfileImage();

                sessionStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_IMAGE, image);
                callback(image);
            })
            .then(response => {
                if (!response || !response.data) {
                    let image = this.getDefaultProfileImage();

                    sessionStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_IMAGE, image);
                    callback(image);

                    return;
                }

                let image = 'data:' + response.mimeType + ';base64,' + response.data;

                sessionStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_IMAGE, image);
                callback(image);
            });

            return;
        }

        callback(profileImage);
    }

    /**
     *
     * @param userId
     * @param callback
     */
    static getUserProfileImageByUserId(userId, callback) {
        if (!userId) {
            return;
        }

        let userImageKey = LOCAL_STORAGE_KEYS.PROFILE_IMAGE + '-' + userId;
        let profileImage = sessionStorage.getItem(userImageKey);
        if (!profileImage) {
            fetch(Routes.ajax.user.getProfileImage + '/' + userId, {
                method: 'GET'
            })
            .then(response => response.json())
            .catch(message => {
                console.warn("Unable to retrieve profile image: " + message);

                let image = this.getDefaultProfileImage();

                sessionStorage.setItem(userImageKey, image);

                callback(image);
            })
            .then(response => {
                if (!response || !response.data) {
                    let image = this.getDefaultProfileImage();

                    sessionStorage.setItem(userImageKey, image);

                    callback(image);

                    return;
                }

                // Data is passed to the front-end as an array of integers. We need to encode this to base64
                let image = 'data:' + response.mimeType + ';base64,' + response.data;

                sessionStorage.setItem(userImageKey, image);

                callback(image);
            });

            return;
        }

        callback(profileImage);
    }

    /**
     *
     * @returns {string}
     */
    static getDefaultProfileImage() {
        return 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxIHBhASBwsSFREUEA8WDhUSCg8REhIWFxcZFxgYE' +
            'xMYHSggGR0lGxYYITIhJTUrLi4uFyAzODMsNygtLi0BCgoKBQUFDgUFDisZExkrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK' +
            'ysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAbAAEAAwEBAQEAAAAAAAAAAAAAAQIFBAMGB//EADgQAQABAwICB' +
            'wYEBAcAAAAAAAABAgQRAyEFMRJBUWFxkbETIjJygcEjM2KhgtHw8RQVNEJSU5L/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAA' +
            'AAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A/cQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAc9zc+y2p5+gPeqqKY96cfVzal7FP' +
            'wRn9ocNdc1zmqcqg6a7uqrlOPCHlOpM86p85eYC8VTHKZ816biqnlVP13eIDv0byKpxqRjv6nWxXtp3NWnTiJ274BqDmtrr2tWK4xPV3' +
            'ukAAAAAAAAAAAAAAAAAAHnranstKZ8mTNXSnMu3iVe1MeMuAFhUBYVAWFQFhUBemro1RMdTYoq6VETHXEMRq2VXSto+vqD3AAAAAAAAA' +
            'AAAAAAAABm8Sn8aPl+8uR1cU/Oj5fvLjBYVAWFQFhUBYVAWalh/po8Z9WS2LKMWtPh9we4AAAAAAAAAAAAAAAAAM/isbUz4wz2nxWYi3' +
            'jPPpRhlgkQAkQAkQAkQAlt221vR8tPow21ZV+0tqcdURE/QHuAAAAAAAAAAAAAAAAADI4rVM3OJ5RTGHG7OLxi4j5Y9ZcOQWFcmQWFcm' +
            'QWFcmQWFcmQWd/Ca8atVPbGfL+7Oy7eE73U/LPrANgAAAAAAAAAAAAAAAAAGbxmj3aKu+Yn6/2ZeX0Nzoxr6M0z18u6WFrWteh+ZTt2x' +
            'vAPPJlXJkFsmVcmQWyZVyZBbJlXJkFstPg1HxVeER6z9nBbW1VxP4cbdczyhu22jFvoxTT9Z7ZB6gAAAAAAAAAAAAAAAAAOfiEdKyr+X' +
            '03dDzuI6VvXH6avQHzQrkBYVAWFQFkZQc+QPpbPT9na0RH/ABjPjO8vZFMdGmI7oSAAAAAAAAAAAAAAAAAAATGY3AHzd9azaauJ+Gfhn' +
            '+utzZaPE+IU3FE0UUztVtVmOrsZgLZMqgLZMqgLZaPCbT22p06/hpnbvlmNWy4pToaFNNVE7dcTEg2hFM9KnNPKeSQAAAAAAAAAAAAAA' +
            'AAARVVFFOa5iI75wCXFxW5/w9rOJ96ran7yjW4rpaXKvpT+mM/vyYV7dTd681Vfwx2QDyyZVyZBbJlXJkFsmVcmQWyZVyZB9BwS59rod' +
            'Cqd6eXfH9fZpPkbfXm31oq053j9+6W/ocX0tSPfmaZ74284B3iunqRqU506omO6YlYAAAAAAAAAAAHDc8V07fbpdKeynfznkDueFxd0W' +
            '0fjVxHdznyYVzxjU1ttP3Y7ufn/ACZ8zmd538QbFzxuZ2tqMd9W8+TM1terXqzrVTM98+kdTxAWFQFhUBYVAWFQFhUBYVAemnqzpVZ06' +
            'pie6cNO243VRtcU9KO2Np/lLIAfWW19p3P5Ve/ZO0+TpfF5dttxXU0OdXSjsq38p5g+nGfbcX09bauejP6uX/p3xOY2kEgAAApq6kaNE' +
            'zq1RERzmWPdcd3xa0fxVfaGfxO9m8uJxPuRM9CPv9XEDpuLzUuPztSZjsziPKHgqAsKgLCoCwqAsKgLCoCwqAsKgLCoCwqAsKgLPXQua' +
            '7efwa5jwnby5PABtW3HZja5ozHbTtPk2rfXpuNPpaNWY9PGHxbosrubPXiqjl/ujtgH2A4v810v+z9gHyggBIgBIgBIgBIgBIgBIgBIg' +
            'BIgBIgBIgBIgBIgBIgBIgBAgBIgBIgBIgBIgBIgBIgBIgBIgBIgBIgBIgBIgBIgBKBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/2Q==';
    }

    /**
     * Retrieves a single gig by id.
     *
     * @param id
     * @param callback
     */
    static getUserGig(id, callback) {
        // TODO: Probably change away from query parameter for this...
        fetch(Routes.ajax.gig.get + '?id=' + id, {
            method: 'GET'
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to retrieve user gig: " + error);
        })
        .then(response => {
            if (!response) {
                console.log('no data');

                return;
            }

            callback(response);
        });
    }

    /**
     * Retrieves all gigs that are related to the current user.
     *
     * @param callback
     */
    static getUserGigs(callback) {
        fetch(Routes.ajax.gig.getAllByUser, {
            method: 'GET'
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to retrieve user gigs: " + error);
        })
        .then(response => {
            if (!response) {
                console.log('no data');

                return;
            }

            callback(response);
        });
    }

    /**
     * Retrieves all notifications that have been sent to a user.
     *
     * @param includeOnlyActive specifies whether to only include active (unread) notifications
     * @param callback
     */
    static getUserNotifications(includeOnlyActive, callback) {
        // Archive the notification
        fetch(Routes.ajax.user.notifications, {
            method: 'GET'
        })
        .then(response => response.json())
        .catch(error => {
            console.error("Unable to retrieve user notifications: " + error);
        })
        .then(response => {
            if (!response) {
                console.log('No notifications found.');

                return;
            }

            callback(response);
        });
    }

}

export default UserService;