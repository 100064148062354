import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import Routes from "../../../common/util/Routes";
import Cookies from "../../../common/util/Cookies";
import moment from "moment";

/**
 * Handles display alerts/notifications.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class NotificationArchive extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        this._getActiveNotifications();
    }

    componentWillUnmount() {
        // Stop polling for notifications
        clearInterval(this.timer);
        this.timer = null;
    }

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col">
                        <h1>Notifications</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="message_preview_column">
                        <div className="elevation-5">
                            {this.state.notifications.map((notification, index) => {
                                let unread = notification.status === 'UNREAD';

                                return (
                                    <div key={notification.id}
                                        className="message_preview">
                                        <div className="message_preview__header">
                                            <div className="message_preview__from">
                                                {notification.source}
                                            </div>
                                            <div className="message_preview__date">
                                                {moment.utc(notification.createDate).local().fromNow()}
                                            </div>

                                            <div className={'message_preview__status ' + (unread ? 'unread' : '')}>
                                                <div className="sr-only">Unread</div>
                                            </div>
                                        </div>
                                        <div className="message_preview__subject">
                                            {notification.subject}
                                        </div>
                                        <div className="message_preview__body" dangerouslySetInnerHTML={{__html: notification.content}} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            notifications: []
        };

        if (props.notifications) {
            initialState.notifications = props.notifications
        }

        return initialState;
    }

    /**
     * Retrieve the latest unread notifications from the backend for the current user.
     *
     * @private
     */
    _getActiveNotifications() {
        // Fetch and then setState
        fetch(Routes.ajax.user.notifications, {
            method: 'GET',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to retrieve active notifications: " + error);
        })
        .then(function(notifications) {
            if (!notifications) {
                console.warn("Found no notifications, or backend is temporarily unavailable.");

                return;
            }

            console.debug("Found active notifications [" + notifications.length + "]");

            this.setState({
                notifications: notifications.reverse()
            })
        }.bind(this));
    }

    /**
     * Closes a notification and archives it.
     *
     * @param index the index of the notification to remove
     * @private
     */
    _close(index) {
        let notification = this.state.notifications[index];

        // Archive the notification
        fetch(Routes.ajax.user.notifications + '/' + notification.id, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to archive notification: " + error);
        })
        .then(function(result) {
            if (result !== 'SUCCESS') {
                console.log('Unable to archive notification.');
            }
        }.bind(this));

        // Remove the notification from the UI
        let notifications = [...this.state.notifications];
        notifications.splice(index, 1);

        this.setState({
            notifications: notifications
        });
    }

}

export default NotificationArchive;