import React  from "react";
import SecureComponent from "../../../../common/util/SecureComponent";
import Routes from "../../../../common/util/Routes";
import {Link} from "react-router-dom";

/**
 * Main entrypoint for the user welcome flow pages.
 *
 * @author Adam Childs
 */
class Who extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <div className="row page_nav">
                    <div className="col-md-6">
                        <h1>Who else is on your team?</h1>
                        <p>Understanding your team and how you work helps us figure out faster and smarter ways for you
                            to get things done.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col my-4">
                                <div className="row team_view">
                                    <div className="col-12">
                                        <ul className="list-inline">
                                            <li className="list-inline-item lt-green">
                                                <a href="#">
                                                    <span className="mx-3">Add your boss <i className="fas fa-plus ml-2" /></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <ul className="list-inline">
                                            <li className="list-inline-item dark">
                                                <img src="/images/user_image.png" alt="Example teammate image" />
                                                <span>Justin Ramedia (you)</span>
                                            </li>
                                            <li className="list-inline-item lt-green">
                                                <a href="#">
                                                    <span className="mx-3">Add a colleague <i className="fas fa-plus ml-2" /></span>
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="list-inline">
                                            <li className="list-inline-item lt-green">
                                                <a href="#">
                                                    <span className="mx-3">Add someone on your team <i className="fas fa-plus ml-2" /></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Link to={Routes.app.user.signup.welcome.when} id="next_step" className="btn btn-dk-green mb-4">Continue</Link>
                                <p><Link to={Routes.app.user.signup.welcome.when}>Skip this step</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Who;