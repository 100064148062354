import React  from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "../../../error/NotFound";
import SecureComponent from "../../../../common/util/SecureComponent";
import Routes from "../../../../common/util/Routes";
import How from "./How";
import Who from "./Who";
import When from "./When";
import Drag from "./Drag";

/**
 * Main entrypoint for the user welcome flow pages.
 *
 * @author Adam Childs
 */
class Welcome extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Switch>
                    <Route exact path={Routes.app.user.signup.welcome.how}
                           render={(props) => <How {...props} /> }
                    />
                    <Route exact path={Routes.app.user.signup.welcome.who}
                           render={(props) => <Who {...props} /> }
                    />
                    <Route exact path={Routes.app.user.signup.welcome.when}
                           render={(props) => <When {...props} /> }
                    />
                    <Route exact path={Routes.app.user.signup.welcome.drag}
                           render={(props) => <Drag {...props} /> }
                    />
                    <Route component={NotFound} />
                </Switch>
            </div>
        );
    }
}

export default Welcome;