import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import moment from "moment";

class InformationalNotification extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    render() {
        return(
            <div className="toast fade show notification" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <strong className="mr-auto">{this.state.notification.subject}</strong>
                    <small>{moment.utc(this.state.notification.createDate).local().fromNow()}</small>
                    <button type="button" className="ml-2 mb-1 close" aria-label="Close" onClick={this.props.close}>
                        <span aria-hidden="true">
                            <i className="fas fa-times" style={{fontSize: '14px'}} />
                        </span>
                    </button>
                </div>
                <div className="toast-body" dangerouslySetInnerHTML={{__html: this.state.notification.content}} />
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            notification: {
                cssClass: '',
                content: ''
            }
        };

        if (props.notification) {
            initialState.notification = props.notification
        }

        return initialState;
    }

}

export default InformationalNotification;