import React, { Component } from "react";
import UserService from "../user/UserService";
import SecureComponent from "./SecureComponent";
import Routes from "./Routes";

/**
 * Simple wrapper Component that verifies a user's JWT existence and also verifies that current user is a SuiteGig
 * Administrator before displaying a component. If the user's authorities does not contain ther administrator role, the
 * user is redirected to the dashboard page.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class AdminComponent extends SecureComponent {

    constructor(props) {
        super(props);

        // Keep in the constructor as we want this to block rendering (for security reasons)
        UserService.isCurrentUserAdministrator(result => {
            if (!result) {
                window.location = Routes.app.dashboard;
            }
        });
    }
}

export default AdminComponent;