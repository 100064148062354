import React from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import moment from "moment";
import Routes from "../../../common/util/Routes";
import UserService from "../../../common/user/UserService";

/**
 * Displays attachment activity for a Gig.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class AttachmentActivity extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        UserService.getUserProfileImageByUserId(this.state.user.id, image => {
            let user = this.state.user;
            user.profileImage = image;

            this.setState({
                user: user
            });
        });
    }

    static getDerivedStateFromProps(props, state) {
        return {
            attachment: {
                filename: props.activity.attachment.filename ? props.activity.attachment.filename : '',
                id: props.activity.attachment.id ? props.activity.attachment.id : -1
            },
            createDate: props.activity.createDate ? props.activity.createDate : '',
            user: {
                id: props.activity.user.id ? props.activity.user.id : '',
                name: props.activity.user.name ? props.activity.user.name : '',
                profileImage: state.user.profileImage ? state.user.profileImage :
                    (props.activity.user.profileImage ? props.activity.user.profileImage : UserService.getDefaultProfileImage())
            }
        };
    }

    render() {
        return (
            <div>
                <div className="gig_activity__event">
                    <div className="gig_activity__user">
                        <img src={this.state.user.profileImage} alt="user image"
                             className="rounded-circle img-fluid" />
                    </div>
                    <div className="gig_activity__description gig_activity__attachment">
                        <a href="#">{this.state.user.name}</a> added the attachment <a href={Routes.ajax.gig.attachment.download + this.state.attachment.id}>{this.state.attachment.filename}</a>.
                    </div>
                    <div className="gig_activity__timestamp">
                        {this.state.createDate ? moment.utc(this.state.createDate).local().fromNow() : ''}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            attachment: {
                filename: '',
                id: -1
            },
            createDate: '',
            user: {
                id: '',
                name: '',
                profileImage: UserService.getDefaultProfileImage(),
            }
        };

        if (props.activity) {
            initialState.createDate = props.activity.createDate ? props.activity.createDate : '';

            if (props.activity.attachment) {
                initialState.attachment.filename = props.activity.attachment.filename ? props.activity.attachment.filename : '';
                initialState.attachment.id = props.activity.attachment.id ? props.activity.attachment.id : -1;
            }

            if (props.activity.user) {
                initialState.user.id = props.activity.user.id ? props.activity.user.id : '';
                initialState.user.name = props.activity.user.name ? props.activity.user.name : '';
            }
        }

        return initialState;
    }

}

export default AttachmentActivity;