import Cookies from "./Cookies";

/**
 * Provides utility functions for operating on SuiteGig JSON Web Tokens.
 *
 * @author Adam Childs
 */
class JWT {

    /**
     * Retrieves the current user's SuiteGig authentication token from the user's cookies (if one exists).
     *
     * @returns {any} the token represented in JSON
     */
    static getAuthenticationToken() {
        let token = Cookies.get("x-suitegig-access-token");
        if (!token) {
            return;
        }

        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        return JSON.parse(window.atob(base64));
    }

}

export default JWT;