import SecureComponent from "../../common/util/SecureComponent";
import React from "react";
import Select from "react-select";

const options = [{
    value: 'BACKLOG',
    label: 'Backlog',
    styles: {
        backgroundColor: '#343A40',
        border: 'none',
        '&:hover': {
            backgroundColor: '#4B535C',
            cursor: 'pointer'
        }
    }
},{
    value: 'IN_PROGRESS',
    label: 'In progress',
    styles: {
        backgroundColor: '#008D8E',
        border: '2px solid white',
        '&:hover': {
            backgroundColor: '#00C0C1',
            cursor: 'pointer'
        }
    }
},{
    value: 'BLOCKED',
    label: 'Blocked',
    styles: {
        backgroundColor: '#F27767',
        border: 'none',
        '&:hover': {
            backgroundColor: '#F6A196',
            cursor: 'pointer'
        }
    }
},{
    value: 'COMPLETED',
    label: 'Completed',
    styles: {
        backgroundColor: '#2CBBAF',
        border: 'none',
        '&:hover': {
            backgroundColor: '#46D4C8',
            cursor: 'pointer'
        }
    }
}];

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#FFF',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12)',
        padding: '1rem'
    }),

    control: (provided, state) => {
        let selectedOption = state.getValue()[0];

        return {
            ...provided,
            ...selectedOption.styles,
            borderRadius: '30px'
        }
    },

    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),

    option: (provided, state) => {
        return {
            ...provided,
            ...state.data.styles,
            borderRadius: '30px',
            margin: '0.5rem 0',
            color: '#FFF',
            transition: 'all .15s ease-in-out'
        };
    },

    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
};

/**
 * Handles display and interaction with the status selector on the gig view.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
export default class Status extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            gig: props.gig,
            status: props.gig.status
        };
    }

    render() {
        return (
            <div className="form-group">
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="h4">Status</h3>
                        <Select
                            id={"status"}
                            name={"status"}
                            value={options[this._getGigStatusIndexInOptions(this.state.status)]}
                            onChange={this._handleStatusChange.bind(this)}
                            options={options}
                            styles={customStyles}
                        />
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @param props
     * @returns {{gig: {}, status: string}}
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            gig: {},
            status: ''
        };

        if (props.status) {
            initialState.status = props.status
        }

        if (props.gig) {
            initialState.gig = props.gig
        }

        return initialState;
    }

    /**
     * Determines the index of the given status value in the options array.
     *
     * @param value the value to locate in the options array
     * @private
     */
    _getGigStatusIndexInOptions(value) {
        for (let i = 0; i < options.length; i++) {
            let current = options[i];

            if (current.value === value) {
                return i;
            }
        }

        console.warn('Unable to locate gig status value in options array for [' + value + ']');

        return 0;
    }

    /**
     * Updates the status of the gig when a user selects a new status.
     *
     * @param event the status changed event
     * @private
     */
    _handleStatusChange(event) {
        let status = event.value;

        if (!status) {
            console.warn('Unable to modify gig status.');

            return;
        }

        console.log('Modifying gig status: ' + status);

        this.props.setStatus(status);
    }

}