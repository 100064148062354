import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import SignupModalForm from "./app/user/signup/SignupModalForm";
import { createStore } from 'redux'
import SuiteGigApp from './common/redux/reducers';
import App from './app/App';
import UserIndustrySelector from "./app/user/signup/UserIndustrySelector";
import UserDropdown from "./app/component/header/UserDropdown";
import Routes from "./common/util/Routes";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";

/**
 * Main entrypoint for any JavaScript actions, including the marketing site and the web application.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
document.addEventListener('DOMContentLoaded', function() {
    const store = createStore(SuiteGigApp);
    const history = createBrowserHistory();

    let element = document.getElementById('suitegig');
    if (element) {
        ReactDOM.render(
            <Router history={history}>
                <Provider store={store}>
                    <App history={history} />
                </Provider>
            </Router>,
            element);

        return; // Stop executing if we're in the app. App.js is the only module we need to load
    }

    // The below modules are used by the marketing site in various locations
    element = document.getElementById('signup-modal-form');
    if (element) {
        ReactDOM.render(<SignupModalForm submissionEndpoint={element.getAttribute('data-endpoint')} />, element);
    }

    element = document.getElementById('user-industry-select');
    if (element) {
        ReactDOM.render(<UserIndustrySelector />, element);
    }

    element = document.getElementById('authenticated-header');
    if (element) {
        ReactDOM.render(
            <Provider store={store}>
                <div>
                    <div className="nav-item" style={{display: "inline-block"}}>
                        <a className="nav-link" href={Routes.app.dashboard}>Dashboard</a>
                    </div>
                    <div style={{display: "inline-block"}}>
                        <UserDropdown />
                    </div>
                </div>
            </Provider>,
            element);
    }
});
