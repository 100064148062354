import React, { Component } from "react";
import Cookies from "../../../common/util/Cookies";

const VALIDATION_ERROR = {
    unknown: 'An unexpected error occurred. Please try again later.',
    invalidEmail: 'Please enter a valid email address.',
    emailExists: 'An account already exists with the given email address.'
};

class SignupModalForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '',
            showError: false,
            errorMessage: '',
            showSuccess: false
        };
    }

    onSubmit() {
        if (!this.state.emailAddress) {
            this.setState({
                showError: true,
                errorMessage: VALIDATION_ERROR.invalidEmail,
                showSuccess: false
            });

            return;
        }

        // Make an ajax request to our backend
        fetch(this.props.submissionEndpoint, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                emailAddress: this.state.emailAddress
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result === 'SUCCESS') {
                    this.setState({
                        showError: false,
                        errorMessage: '',
                        showSuccess: true
                    });
                } else {
                    let errorMessage = '';
                    switch(result) {
                        case 'ERROR':
                            errorMessage = VALIDATION_ERROR.unknown;
                            break;
                        case 'ERROR_EMAIL_MISSING':
                        case 'ERROR_EMAIL_INVALID':
                            errorMessage = VALIDATION_ERROR.invalidEmail;
                            break;
                        case 'ERROR_EMAIL_ALREADY_EXISTS':
                            // TODO: On each failure, increase time until user allowed to click Sign Up button again to
                            //  prevent brute force data mining
                            errorMessage = VALIDATION_ERROR.emailExists;
                            break;

                        default:
                            errorMessage = VALIDATION_ERROR.unknown;
                            break;
                    }

                    this.setState({
                        showError: true,
                        errorMessage: errorMessage,
                        showSuccess: false
                    });
                }
            },
            (error) => {
                this.setState({
                    showError: true,
                    errorMessage: VALIDATION_ERROR.unknown,
                    showSuccess: false
                });
            }
        )
    }

    onChange(event) {
        this.setState({
            emailAddress: event.target.value
        });
    }

    render() {
        return(
            <div className="validate form-inline single-field-form">
                <div id="mc_embed_signup_scroll" className="form-group">
                    <div className="mc-field-group">
                        <label htmlFor="email" className="sr-only">Email Address</label>
                        <input type="email" name="EMAIL" placeholder="email@website.com" autoComplete="email"
                               className="required email form-control" id="email" onChange={this.onChange.bind(this)} />
                    </div>

                    <div className="clear">
                        <input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe"
                               className="button btn btn-primary early_access_submit" onClick={this.onSubmit.bind(this)} />
                    </div>

                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response"
                             style={this.state.showError ? {display: 'block'} : {display: 'none'}}>
                            {this.state.errorMessage}
                        </div>
                        <div className="response" id="mce-success-response"
                             style={this.state.showSuccess ? {display: 'block'} : {display: 'none'}}>
                            Success! We've sent you an email to verify your account.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignupModalForm;