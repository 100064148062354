import React from "react";
import SecureComponent from "../../../common/util/SecureComponent";
import moment from "moment";
import UserService from "../../../common/user/UserService";

/**
 * Displays miscellaneous gig activity for a Gig.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class GigActivity extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    componentDidMount() {
        UserService.getUserProfileImageByUserId(this.state.user.id, image => {
            let user = this.state.user;
            user.profileImage = image;

            this.setState({
                user: user
            });
        });
    }

    static getDerivedStateFromProps(props, state) {
        return {
            createDate: props.activity.createDate ? props.activity.createDate : '',
            subType: props.activity.subType ? props.activity.subType : '',
            type: props.activity.type ? props.activity.type : '',
            user: {
                id: props.activity.user.id ? props.activity.user.id : '',
                name: props.activity.user.name ? props.activity.user.name : '',
                profileImage: state.user.profileImage ? state.user.profileImage :
                    (props.activity.user.profileImage ? props.activity.user.profileImage : UserService.getDefaultProfileImage())
            }
        };
    }

    render() {
        let actionMap = {
            'ARCHIVED': 'archived',
            'CREATED': 'created',
            'UPDATED': 'updated',
            'UNARCHIVED': 're-opened',
            'DELETED': 'removed'
        };

        return (
            <div>
                <div className="gig_activity__event">
                    <div className="gig_activity__user">
                        <img src={this.state.user.profileImage} alt="user image"
                             className="rounded-circle img-fluid" />
                    </div>
                    <div className="gig_activity__description">
                        <a href="#">{this.state.user.name}</a> {actionMap[this.state.subType] || 'updated'} this gig.
                    </div>
                    <div className="gig_activity__timestamp">
                        {this.state.createDate ? moment.utc(this.state.createDate).local().fromNow() : ''}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Determines the initial state of the component based on whether or not properties from Redux global state exist.
     *
     * @param props potential default data from redux global state
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            createDate: '',
            subType: '',
            type: '',
            user: {
                id: '',
                name: '',
                profileImage: UserService.getDefaultProfileImage(),
            }
        };

        if (props.activity) {
            initialState.subType = props.activity.subType ? props.activity.subType : '';
            initialState.type = props.activity.type ? props.activity.type : '';
        }

        return initialState;
    }

}

export default GigActivity;