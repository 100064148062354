/**
 * Provides utility functions for dealing with browser cookies.
 *
 * @author Adam Childs
 */
class Cookies {

    /**
     * Gets the value held by the cookie with the given `name`.
     *
     * @param name the name of the cookie
     * @returns {string} the value held by the cookie with the given name
     */
    static get(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");

        if (parts.length === 2)
            return parts.pop().split(";").shift();
    }

    /**
     * Returns the current CSRF token, if one exists.
     *
     * @returns {string}
     */
    static getCsrfToken() {
        return this.get('XSRF-TOKEN');
    }

}

export default Cookies;