import React from "react";
import SecureComponent from "../../common/util/SecureComponent";
import Routes from "../../common/util/Routes";
import Cookies from "../../common/util/Cookies";
import UserService from "../../common/user/UserService";
import DatePicker from "react-datepicker";

/**
 * Displays the main Gig creation view.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class CreateGig extends SecureComponent {

    constructor(props) {
        super(props);

        // If we have initial state from Redux, use it
        this.state = {
            formError: null,
            showSuccess: false,
            submitEnabled: false,
            title: '',
            description: '',
            dueDate: '',
            startDate: '',
            prevPath: ''
        };
    }

    componentDidUpdate(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location })
        }
    }

    render() {
        return(
            <div>
                <div className="new_gig bg-dk-green show">
                    <div className="close_gig">
                        <i className="fas fa-times" onClick={this._close.bind(this)} />
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="newGigName">Gig Name</label>
                                <input type="text"
                                       value={this.state.title}
                                       onChange={(e) => this.setState({title: e.target.value})}
                                       className="form-control form-control-lg"
                                       id="newGigName"
                                       aria-describedby=""
                                       placeholder="Enter a gig name"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newGigDescription">Description</label>
                                <textarea className="form-control"
                                          id="newGigDescription"
                                          placeholder=""
                                          value={this.state.description}
                                          onChange={(e) => this.setState({description: e.target.value})}
                                />
                            </div>
                            <div className="form-group">
                                <label>Start Date</label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.setState({startDate: date})}
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd hh:mm aa"
                                    timeFormat="hh:mm aa"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    onChangeRaw={e => e.preventDefault()}
                                    className="form-control"
                                    id="datepicker"
                                    placeholderText="Click to select"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group">
                                <label>Due Date</label>
                                <DatePicker
                                    selected={this.state.dueDate}
                                    onChange={(date) => this.setState({dueDate: date})}
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd hh:mm aa"
                                    timeFormat="hh:mm aa"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    onChangeRaw={e => e.preventDefault()}
                                    className="form-control"
                                    id="datepicker"
                                    placeholderText="Click to select"
                                    autoComplete="off"
                                />
                            </div>

                            <button className="btn btn-dark" onClick={this._addGig.bind(this)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Closes the create gig popup, displaying the user's dashboard.
     *
     * @private
     */
    _close() {
        this.props.history.push(Routes.app.dashboard);
    }

    /**
     *
     * @param submission
     * @returns {boolean}
     * @private
     */
    _doValidate(submission) {
        if (!submission.title || submission.title.length === 0) {
            this.setState({formError: 'A gig must be given a title.'});
            console.log('A gig must be given a title.');

            return false;
        }

        // All validations passed, so remove any form errors
        this.setState({formError: null});

        return true;
    }

    /**
     *
     * @returns {string}
     * @private
     */
    _getAddGigRequest() {
        return JSON.stringify({
            title: this.state.title,
            description: this.state.description,
            dueDate: new Date(this.state.dueDate).toISOString(),
            startDate: new Date(this.state.startDate).toISOString()
        });
    }

    /**
     *
     * @private
     */
    _addGig() {
        // Validate
        let success = this._doValidate(this.state);
        if (!success) {
            // TODO: Show an appropriate error message
            return;
        }

        // Save new Gig
        fetch(Routes.ajax.gig.add, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: this._getAddGigRequest()
        })
        .then(response => response.json())
        .catch(error => {
            console.log("Unable to add new gig: " + error);
        })
        .then(
            function(response) {
                if (response !== 'SUCCESS') {
                    // TODO: Show error message
                    console.log("Unable to add new gig: " + response);

                    return;
                }

                // Reset fields to allow user to enter another gig
                this._resetFields();
            }.bind(this),
            function(error) {
                // TODO: Show an error message
                console.error(error);
            }
        )
        .then(function() {
            UserService.getUserGigs(data => this.props.loadUserGigs(data));
        }.bind(this));
    }

    _resetFields() {
        this.setState({
            title: '',
            description: '',
            dueDate: '',
            startDate: ''
        });
    }

}

export default CreateGig;