import React  from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "../error/NotFound";
import Routes from "../../common/util/Routes";
import AdminDashboard from "./AdminDashboard";
import AdminComponent from "../../common/util/AdminComponent";
import AdminNotifications from "./AdminNotifications";
import AdminUsers from "./AdminUsers";

/**
 * Entrypoint for "/app/admin/**" pages, routing to the appropriate components based off path.
 *
 * @author Adam Childs
 */
class Admin extends AdminComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className='admin'>
                <Switch>
                    <Route path={Routes.app.admin.dashboard} render={(props) => <AdminDashboard {...props} /> } />
                    <Route path={Routes.app.admin.notifications} render={(props) => <AdminNotifications {...props} /> } />
                    <Route path={Routes.app.admin.users} render={(props) => <AdminUsers {...props} /> } />
                    <Route component={NotFound}/>
                </Switch>
            </div>
        );
    }
}

export default Admin;