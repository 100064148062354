import React  from "react";
import SecureComponent from "../../../../common/util/SecureComponent";
import Routes from "../../../../common/util/Routes";
import {Link} from "react-router-dom";
import Cookies from "../../../../common/util/Cookies";

/**
 * Main entrypoint for the user welcome flow pages.
 *
 * @author Adam Childs
 */
class How extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <div className="row page_nav">
                    <div className="col-md-6">
                        <h1>Welcome to SuiteGig!</h1>
                        {/* TODO: Get URL for 'work in sprints' */}
                        <p>We're still learning about how every team works, but based on the type of work you do, we're
                            guessing you <a href="">work in sprints</a>.</p>
                        <p>
                            <Link to={Routes.app.user.signup.welcome.who}
                                  onClick={() => this._persistWorksInSprints(true)}
                                  className="mr-3">
                                <i className="fas fa-check-circle" /> Yep that's us
                            </Link>
                            <Link to={Routes.app.user.signup.welcome.who}
                                  onClick={() => this._persistWorksInSprints(false)}
                                  className="mr-3">
                                <i className="fas fa-times-circle" /> Wrongo, friend
                            </Link>
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col my-4">
                                <img src="/images/welcome_sprint_board.png" alt="Agile Sprint Board"
                                     className="img-fluid" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <p><Link to={Routes.app.user.signup.welcome.who}>Skip this step</Link></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="welcome_transition">
                    <div className="container h-100">
                        <div className="row h-50 align-items-center justify-content-center">
                            <div className="col-md-6 text-center text-white">
                                <h1>Great!</h1>
                                <h2 className="display-4"><i className="fas fa-thumbs-up" /></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @param worksInSprints
     * @private
     */
    _persistWorksInSprints(worksInSprints) {
        // Save user
        fetch(Routes.ajax.user.update.signup.welcome, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': Cookies.getCsrfToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                worksInSprints: worksInSprints
            })
        })
            .then(response => response.json())
            .catch(error => {
                console.log("Unable to update user: " + error);
            })
            .then(
                function(response) {
                    if (response === 'SUCCESS') {
                        console.log("Successfully updated user preference: worksInSprints=" + worksInSprints);
                    } else {
                        // TODO: Show error message
                        console.error(response);
                    }
                }.bind(this),
                function(error) {
                    // TODO: Show an error message
                    console.error(error);
                }
            )
    }
}

export default How;