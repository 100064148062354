import React  from "react";
import SecureComponent from "../../../../common/util/SecureComponent";

/**
 * Main entrypoint for the user welcome flow pages.
 *
 * @author Adam Childs
 */
class Drag extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                Drag
            </div>
        );
    }
}

export default Drag;