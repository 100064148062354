import React  from "react";
import SecureComponent from "../../../common/util/SecureComponent";

/**
 * Handles display and interaction with the account upgrade page.
 *
 * @author Adam Childs
 */
class Upgrade extends SecureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <div className="row align-items-start">
                    <div className="col-md-3 pricing">
                        <h3 className="d-block">Basic</h3>
                        <h4 className="d-block">$5 / user</h4>
                        <ul className="list-unstyled">
                            <li>3 GB of storage</li>
                            <li>50 Gigs (Projects)</li>
                            <li>Basic task &amp; project management</li>
                            <li>Basic support</li>
                        </ul>
                        <a href="#" className="d-block d-md-none btn btn-primary">Select</a>
                    </div>
                    <div className="col-md-3 pricing">
                        <h3>Team</h3>
                        <h4>$8 / user</h4>
                        <ul className="list-unstyled">
                            <li>15 GB of storage</li>
                            <li>Unlimited Gigs</li>
                            <li>Advanced task &amp; project management</li>
                            <li>Priority email &amp; phone support</li>
                            <li>Collaboration suite</li>
                            <li>Executive dashboards</li>
                            <li>Limited API access</li>
                        </ul>
                        <a href="#" className="d-block d-md-none btn btn-primary">Select</a>
                    </div>
                    <div className="col-md-3 pricing">
                        <h3>Pro</h3>
                        <h4>$15 / user</h4>
                        <ul className="list-unstyled">
                            <li>Unlimited storage</li>
                            <li>Unlimited Gigs</li>
                            <li>AI-enabled task &amp; project management</li>
                            <li>Email support</li>
                            <li>Collaboration suite</li>
                            <li>AI helpers</li>
                            <li>Custom dashboards</li>
                            <li> Dashboard templates</li>
                            <li>Full API access</li>
                        </ul>
                        <a href="#" className="d-block d-md-none btn btn-primary">Select</a>
                    </div>
                    <div className="col-md-3 pricing">
                        <h3>Enterprise</h3>
                        <h4><a href="tel:8002381982">Call us now</a></h4>
                        <ul className="list-unstyled">
                            <li>Unlimited storage</li>
                            <li>Unlimited Gigs</li>
                            <li>AI-enabled task &amp; project management</li>
                            <li>24/7 VIP support</li>
                            <li>VIP collaboration suite</li>
                            <li>AI helpers</li>
                            <li>Custom dashboards</li>
                            <li>Dashboard templates</li>
                            <li> Customer success expert</li>
                            <li>Full API access</li>
                        </ul>
                        <a href="#" className="d-block d-md-none btn btn-primary">Select</a>
                    </div>
                </div>

                <div className="row align-items-end d-none d-md-flex">
                    <div className="col-md-3">
                        <a href="#" className="btn btn-primary">Select Basic Plan</a>
                    </div>
                    <div className="col-md-3">
                        <a href="#" className="btn btn-primary">Select Team Plan</a>
                    </div>
                    <div className="col-md-3">
                        <a href="#" className="btn btn-primary">Select Pro Plan</a>
                    </div>
                    <div className="col-md-3">
                        <a href="tel:8002381982" className="btn btn-primary">Call us now</a>
                    </div>
                </div>

                {
                    // Team size
                }
                <div className="row mt-5 pt-5 mb-3">
                    <div className="col">
                        <h2>Team size</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-5">
                        <div className="form-group">
                            <label htmlFor="upgradeTeamSize" className="sr-only">Team size</label>
                            <input type="text" className="form-control" id="upgradeTeamSize"
                                   aria-describedby="teamSizeHelp" placeholder="12" />
                            <small id="teamSizeHelp" className="form-text text-muted">Enter the size of your team.</small>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <p>Anyone that does work on your <strong>team</strong> is a user. Clients and other departments
                            in your company will be able to view certain things, but only team members can manage work.
                        </p>
                    </div>
                </div>

                <div className="row mt-5 mb-3">
                    <div className="col">
                        <h2>Super user &nbsp;<i className="fas fa-bolt text-medium" /></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="form-group">
                            <label htmlFor="superUserEmail" className="sr-only">Super user</label>
                            <input type="text" className="form-control" id="superUserEmail"
                                   aria-describedby="superUserEmailHelp" placeholder="john.suite@example.com" />
                            <small id="superUserEmailHelp" className="form-text text-muted">Enter the email address
                                of the person that will be managing your account.</small>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <p><i className="fas fa-bolt" /> A super user will have access to every area of your account.
                            They will be your administrator and all account information will be sent to them.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>

                {
                    // Billing info
                }
                <div className="row mt-5 pt-5 mb-3">
                    <div className="col">
                        <h2>Billing information</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="form-group">
                            <a href="#" className="btn btn-primary btn-block p-0">
                                <span style={{fontSize: '2.5em', lineHeight: '3rem'}}><i className="fab fa-apple-pay" /></span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <p>Pay using Apple Pay.<br />
                            100% secure - requires fingerprint or face ID.</p>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-md-5 text-center">
                        <h4 className="line-behind text-dk-green"><span>Or</span></h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-5">
                        <div className="form-group">
                            <label htmlFor="creditCardNumber" className="sr-only">Credit Card Number</label>
                            <input type="text" className="form-control" id="creditCardNumber" aria-describedby=""
                                   placeholder="" />
                        </div>

                        <div className="form-group">
                            <ul className="list-inline">
                                <li className="list-inline-item mr-3">
                                    <a href="#" className="dropdown-toggle mr-4" id="upgradeCCMonthDropdown"
                                       role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Month</a>
                                    <div className="dropdown-menu" aria-labelledby="upgradeCCMonthDropdown">
                                        <a className="dropdown-item" href="#">January</a>
                                        <a className="dropdown-item" href="#">February</a>
                                        <a className="dropdown-item" href="#">March</a>
                                        <a className="dropdown-item" href="#">April</a>
                                        <a className="dropdown-item" href="#">May</a>
                                        <a className="dropdown-item" href="#">June</a>
                                        <a className="dropdown-item" href="#">July</a>
                                        <a className="dropdown-item" href="#">August</a>
                                        <a className="dropdown-item" href="#">September</a>
                                        <a className="dropdown-item" href="#">October</a>
                                        <a className="dropdown-item" href="#">November</a>
                                        <a className="dropdown-item" href="#">December</a>
                                    </div>
                                </li>
                                <li className="list-inline-item mr-3">
                                    <a href="#" className="dropdown-toggle" id="upgradeCCYearDropdown" role="button"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Year</a>
                                    <div className="dropdown-menu" aria-labelledby="upgradeCCYearDropdown">
                                        <a className="dropdown-item" href="#">2019</a>
                                        <a className="dropdown-item" href="#">2020</a>
                                        <a className="dropdown-item" href="#">2021</a>
                                        <a className="dropdown-item" href="#">2022</a>
                                        <a className="dropdown-item" href="#">2023</a>
                                        <a className="dropdown-item" href="#">2024</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="form-group">
                            <label htmlFor="ccv" className="sr-only">CCV</label>
                            <input type="text" className="form-control" id="ccv" aria-describedby="" placeholder="" />
                        </div>
                    </div>

                    <div className="col-md-7 d-flex align-items-end">
                        <img src="/static/images/ccv_visa.png" className="mr-3" alt="Visa CCV location"
                             style={{width: '140px', height: 'auto'}} />
                        <img src="/static/images/ccv_amex.png" alt="American Express CCV location"
                             style={{width: '140px', height: 'auto'}} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        );
    }

}

export default Upgrade;