import SecureComponent from "../../common/util/SecureComponent";
import Attachment from "./Attachment";
import React from "react";

/**
 * Handles display and interaction with the attachments section on the gig view.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
export default class Attachments extends SecureComponent {

    constructor(props) {
        super(props);

        this.state = this._getInitialState(props);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            gig: props.gig,
            attachments: props.gig.attachments
        };
    }

    render() {
        return (
            <div className="form-group">
                <h3 className="h4">Attachments</h3>
                <div className="row">
                    <div className="col">
                        {this.state.attachments.map((attachment, index) => {
                            return (
                                <Attachment key={index}
                                            attachment={attachment}
                                            gig={this.state.gig} />
                            );
                        })}

                        <Attachment isAdd={true}
                                    gig={this.state.gig}
                                    updateAttachmentList={this.props.updateAttachmentList.bind(this)} />
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     * @param props
     * @returns {{attachments: *[]}}
     * @private
     */
    _getInitialState(props) {
        let initialState = {
            gig: {},
            attachments: []
        };

        if (props.gig) {
            initialState.gig = props.gig;
            initialState.attachments = props.gig.attachments;
        }

        return initialState;
    }

}