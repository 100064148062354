import React, { Component } from "react";
import InvitationModal from "./InvitationModal";
import ShareModal from "../../gig/ShareModal";

/**
 * Initializes any modals that may be displayed at some point.
 *
 * @author Adam Childs
 * @since 1.0.0
 */
class Modals extends Component {

    render() {
        return(
            <div>
                <InvitationModal />
                <ShareModal />
            </div>
        );
    }
}

export default Modals;